import Next from './components/Next.js';
import ViewNewRequest from './pages/ViewNewRequest.jsx';

import AddOrder from'./pages/AddOrder.jsx';

import SentOffers from './pages/SentOffers.jsx';
import ViewSentOffer from './pages/ViewSentOffer.jsx';
import EditSentOffer from './pages/EditSentOffer.jsx';
import ReviseCost from './pages/ReviseCost.jsx';
import ManualUserPayments from './pages/ManualUserPayment.jsx';
import ManualSubPayment from './pages/ManualSubPayment.jsx';

import SharedComponent from'./components/SharedComponent.js';
import Error404 from './pages/Error404.jsx';
import Login from './components/Login.js';
import Register from './components/Register.js';
import ScrollToTop from './components/ScrollToTop.js';

import SendQuote from './pages/SendQuote.jsx';
import AcceptedOffers from './pages/AcceptedOffers.jsx';

import ViewRevenue from './pages/ViewRevenue.jsx';
import CancelledOffers from './pages/CancelledOffers.jsx';
import ActiveOrders from './pages/ActiveOrders.jsx';
import ViewActive from './components/ViewActive.js';
import CompletedOrders from './pages/CompletedOrders.jsx';
import Revenue from './pages/Revenue.jsx';
import Subcontractors from './pages/Subcontractors.jsx';
import EditNewRequest from './pages/EditNewRequest';
import ShowQuery from './pages/ShowQuery.jsx';
import ViewSubcontractor from './pages/ViewSubcontractor'
import EditSub from './components/EditSub'
import OrderHistory from './components/OrderHistory'
import Preview from './components/Preview'

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ViewCert from './components/ViewCert.js';
import SentToSubcontractor from './pages/SentToSubcontractor.jsx';
import Alt from './components/Alt.jsx';
import Dashboard from './pages/Dashboard.jsx';
import Dummy from './components/Dummy.jsx';
import Query from './pages/Query.jsx';
import NewRequests from './pages/NewRequests.jsx';

import AddBlog from './pages/AddBlog.jsx';
import OrderInvoices from './pages/OrderInvoices.jsx';
import OrderSubInvoices from './pages/OrderSubInvoices.jsx';


import ViewAcceptedOffer from './pages/ViewAcceptedOffer.jsx';
import ViewSentToSubOffer from './pages/ViewSentToSubOffer.jsx';
import ViewActiveOffer from './pages/ViewActiveOffer.jsx';
import AddSubcontractor from './pages/AddSubcontractor.jsx';
import ViewCancelledOrder from './pages/ViewCancelledOrder.jsx';
import EditCancelledOrder from './pages/EditCancelledOrder.jsx';
import AllBlogs from './pages/AllBlogs.jsx';
import SubHistory from './pages/SubHistory.jsx';
import ViewBlog from './pages/ViewBlog.jsx';
import ViewCompleted from './pages/ViewCompleted.jsx';
// import AddSub from './components/AddSub.js';



function App() {
  return (
    <>
    <Router>

      <ScrollToTop/>
    
<Routes>
<Route path="/login" element={<Login/>} />
<Route path="/alt" element={<Alt/>} />
<Route path="/register" element={<Register/>} />
  <Route path="/" element={<SharedComponent/>} >
    <Route index element={<Dashboard/>} />
    <Route path="/newrequest" element={<NewRequests/>} />
    <Route path="/home" element={<Dashboard/>} />
    <Route path="/dummy" element={<Dummy/>} />
    <Route path="/sub-history" element={<SubHistory/>} />
    <Route path="/viewnewrequest" element={<ViewNewRequest/>} />
    <Route path="/viewsentoffer" element={<ViewSentOffer/>} />
    <Route path="/viewaccepted" element={<ViewAcceptedOffer/>} />
    <Route path="/viewsenttosub" element={<ViewSentToSubOffer/>} />
    <Route path="/viewactive" element={<ViewActiveOffer/>} />


    <Route path="/orderinvoices" element={<OrderInvoices/>} />
    <Route path="/subinvoices" element={<OrderSubInvoices/>} />

    
    <Route path="/addblog" element={<AddBlog/>} />
    <Route path="/view-blog" element={<ViewBlog/>} />
    <Route path="/addorder" element={<AddOrder/>} />
    <Route path="/preview" element={<Preview/>} />
    <Route path="/activeorders" element={<ActiveOrders/>} />
    <Route path="/view-active" element={<ViewActive/>} />
    <Route path="/view-complete" element={<ViewCompleted/>} />
    <Route path="/completedorders" element={<CompletedOrders/>} />

    <Route path="/sentoffers" element={<SentOffers/>} />
    <Route path="/editsentoffer" element={<EditSentOffer/>} />
    <Route path="/revisecost" element={<ReviseCost/>} />
    <Route path="/manualuserpayments" element={<ManualUserPayments/>} />
    <Route path="/manualsubpayments" element={<ManualSubPayment/>} />

    <Route path="/cancelledoffers" element={<CancelledOffers/>} />
    <Route path="/view-cancelled" element={<ViewCancelledOrder/>} />
    <Route path="/edit-cancelled" element={<EditCancelledOrder/>} />
    
    <Route path="/acceptedoffers" element={<AcceptedOffers/>} />
    <Route path="/orderhistory" element={<OrderHistory/>} />
    <Route path="/revenue" element={<Revenue/>} />
    <Route path="/next" element={<Next/>} />
    <Route path="/sendquote" element={<SendQuote/>} />
    
    <Route path="/senttosub" element={<SentToSubcontractor/>} />
    <Route path="/subcontractors" element={<Subcontractors/>} />
    <Route path="/view-subcontractor" element={<ViewSubcontractor/>} />
    <Route path="/view-cert" element={<ViewCert/>} />
    <Route path="/edit-sub" element={<EditSub/>} />
    <Route path="/addsub" element={<AddSubcontractor/>} />
    <Route path="/viewsub" element={<ViewSubcontractor/>} />

    <Route path="/allblogs" element={<AllBlogs/>} />
    <Route path="/query" element={<Query/>} />
    <Route path="/showquery" element={<ShowQuery/>} />



    <Route path="/view-revenue" element={<ViewRevenue/>} />
    <Route path="/orderhistory" element={<OrderHistory/>} />
    <Route path="/edit" element={<EditNewRequest/>} />
    <Route path="*" element={<Error404/>} />
   
  </Route>
   
</Routes>


    </Router>


      
    
    </>
    
  );
}

export default App;
