import React, { useState, useEffect , useRef } from 'react';
import {  useNavigate } from "react-router-dom"


import { FiDownload } from 'react-icons/fi';


import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';

import config from '../config/config';
import HeadBar from '../partials/HeadBar';



function SubHistory() {
   
  const navigate = useNavigate();
  const componentRef = useRef(null);


  const [isOpen, setIsOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
const [user, setUser] = useState('')


// const [loading, setLoading] = useState(true)

useEffect(() => {

fetch(`${config.baseUrl}/api/sub/history?id=${localStorage.getItem('subHistoryId')}`, {
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
})
  .then((response) => response.json())
  .then((data) => {
    console.log("data", data)
  }, [] )


}, []);



 
 

 
  return (

    
    <div className="flex h-screen overflow-hidden">

{/* Sidebar */}
<Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

{/* Content area */}
<div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

  {/*  Site header */}
  <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

  <main ref={componentRef}  >
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

      <HeadBar title={"Geschichte des Subunternehmers"} description={""}/>


 
      </div>
   
   
{/* {loading && <p className='ml-[300px] p-[30px] font-bold text-[30px] text-blue-400'>Lade Date <img className='inline-block' src="./images/spin.gif" alt="" /></p>
}

{ !loading && null

} */}
<img
                    className="block mx-auto"
                    src="https://entsorg-meistet-bucket.fra1.digitaloceanspaces.com/em%2Fno-blogs.png"
                    alt="Keine Bestellhistorie gefunden"
                  />
                  <p className="md:text-[28px]  text-[18px] mt-2 text-center text-slate-400 font-semibol ">
                  Keine Bestellhistorie gefunden!
                  </p>
   </main>

  







 </div>
</div>
);
}

export default SubHistory