import React, { useState, useEffect , useRef } from 'react';
import {  useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FaChevronCircleDown } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';
import toast, { Toaster } from "react-hot-toast";


import moment from 'moment';
import Select from 'react-select'
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import WelcomeBanner from '../partials/WelcomeBanner';
import config from '../config/config';
import Block from '../partials/Block';

import { BsCalendar } from 'react-icons/bs';
import { BsSearch } from 'react-icons/bs';
import HeadBar from '../partials/HeadBar';

const options = [
  { value: '1', label: 'Älteste' },
  { value: '-1', label: 'Neueste' },

]



function SendQuote() {
    const { t } = useTranslation()

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [cost, setCost] =useState(null)
  const [userper, setUserper] =useState(30)
  const [subper, setSubper] =useState(10)
  const [id, setId] =useState('')
  const [loading, setLoading] = useState(true)
const [success, setSuccess] = useState('')

const goToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: 'smooth',
  });

  const interval = setTimeout(() => {
   setSuccess('')
   navigate("/sentoffers");
  
  }, 4000);

  return () => clearInterval(interval);

};

  useEffect(() => {
    setId(localStorage.getItem('sendQuoteId'))
  },[])

  const notify = (text) => toast.success(text);

async function handleCost(event) { 
  const response = await fetch(`${config.baseUrl}/api/cost`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id:localStorage.getItem('sendQuoteId'),
      cost:cost,
      userper:userper,
      subper:subper,
    }),
  })

  const data = await response.json()

  if (data.status === 'ok') {
 notify("Das Angebot wurde an den Benutzer gesendet")
    goToTop()
    setSuccess("success")
  }
  else{
    goToTop()
    setSuccess("error")
  }

  

 
} 

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main  >
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            <HeadBar title={"Neue Anfragen"} description={"Werfen Sie einen Blick auf neue Anfragen"}/>

            <div className=" ml-[20px] w-[90vw] max-w-[800px] rounded text-[#333] bg-white   shadow-lg shadow-grey border-2 border-grey p-[45px]">

            <Toaster />


<div class="parallel  justify-around items-center mb-[30px]">
  <form onSubmit={(e)=>{e.preventDefault()}}>
  {success==='success' && <div className='mb-[20px]'>
<p className='bg-blue-100 border-[1px] border-solid border-blue-500 w-[100%] px-[30px] py-[10px] '>Das Angebot wurde erfolgreich versendet! Sie werden in Kürze automatisch weitergeleitet</p>
</div>}
{success==='error' && <div className='mb-[20px]'>
<p className='bg-red-100 border-[1px] border-solid border-red-500 w-[100%] px-[30px] py-[10px] '>Ein Fehler ist aufgetreten. Bitte versuche es erneut</p>
</div>}
  <div className="form-box mb-[10px] flex">
 <input className='bg-white px-[10px]  py-[5px]  shadow-lg border-2 border-grey' type="number" min={30} required placeholder ="Kosten " 
 onChange={event => setCost(event.target.value)} value={cost} />
  <label  className='w-[280px] px-[10px]  py-[5px] text-white bg-indigo-500'>{t('cost')} Є</label>
  </div>
  <div className="form-box mb-[10px] flex">
 
  <select className="bg-white px-[10px] w-[220px] py-[5px] shadow-lg border-2 border-grey" required name="userper" defaultValue={userper} onChange={event => setUserper(event.target.value)} >
            <option  value="20">20 %</option>
            <option value="25">25 %</option>
            <option value="30" >30 %</option>
            <option value="35">35 %</option>
        </select>
<label  className='w-[280px] px-[10px]  py-[5px] text-white bg-indigo-500'>{t('advance-cost')} % ({t('user')})</label>
  </div>

  <div className="form-box mb-[10px] flex">

  <select className="bg-white px-[10px] w-[220px] py-[5px] shadow-lg border-2 border-grey" required name="subper" defaultValue={subper} onChange={event => setSubper(event.target.value)} >
            <option  value="10" >10 %</option>
            <option value="15">15 %</option>
            <option value="20" >20 %</option>

        </select>    <label  className='w-[280px] px-[10px]  py-[5px] text-white bg-indigo-500'>{t('advance-cost')} % ({t('subcontractor')})</label>
   </div>
   </form>
  </div>
  {(cost<1 ) ? 

   <button
   disabled 
   
   className="cursor-not-allowed text-sm ml-3   object-contain px-8 py-2.5 font-semibold text-white bg-gray-300 border-b-4 border-gray-400 rounded shadow-indigo-500 shadow-sm "
 >
   <FiDownload className="text-white text-[18px] mr-2 mb-0.75 inline-block" />{" "}
   Angebot senden
    </button>

  :
  <button
  
  onClick ={handleCost}
  className="text-md ml-3 hover:translate-y-[2px] transition  object-contain px-8 py-2.5 font-semibold text-white bg-green-500 border-b-4 border-green-800 rounded shadow-green-500 shadow-sm hover:border-green-600"
>
<svg className='inline-block mr-2' xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="white"  viewBox="0 0 16 16" id="IconChangeColor"> <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" id="mainIconPathAttribute"></path> </svg>

  Angebot senden
   </button>
/* {(cost<1 ) ?  <button disabled onClick ={handlePreview} className='px-[30px] mr-[30px] py-[6px] font-semibold text-[16px] text-white bg-orange-800 text-grey'>{t('Preview')}</button>
  :
  <button  onClick ={handlePreview} className='px-[30px] mr-[30px] py-[6px] font-semibold text-[16px] text-white bg-orange-500 hover:bg-orange-700'>Preview</button>} */}
</div>

          </div>
   
        </main>

       

      </div>
    </div>
  );
}

export default SendQuote;