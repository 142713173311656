import React, { useState, useEffect , useRef } from 'react';
import {  useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';


import { FaChevronCircleDown } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';

import moment from 'moment';
import Select from 'react-select'
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import WelcomeBanner from '../partials/WelcomeBanner';
import config from '../config/config';
import Block from '../partials/Block';

import { BsCalendar } from 'react-icons/bs';
import { BsSearch } from 'react-icons/bs';
import HeadBar from '../partials/HeadBar';
import EditForm from '../partials/EditForm';

const options = [
  { value: '1', label: 'Älteste' },
  { value: '-1', label: 'Neueste' },

]



function EditNewRequest() {
    const { t } = useTranslation();
    const [name, setName] = useState('')
    const [value, setValue] = useState('')
    const [sliderVal, setSliderValue] = useState(0)
    const [email, setEmail] = useState('')
    const [contact, setContact] = useState('')
    const [address, setAddress] = useState('')
    const [section, setSection] = useState('')
    const [others, setOthers] = useState('')
    const [method, setMethod] = useState('')
    const [date, setDate] = useState('')
    const [floor, setFloor] = useState('')
    
    const [postcode, setPostcode] = useState('')
    const [city, setCity] = useState('')
    const [description, setDescription] = useState('')
    const [orderStatus, setOrderStatus] = useState('')

    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState('')
    const [isOpen, setIsOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

const [orderData, setOrderData] = useState('')
    const goToTop = () => {
      window.scrollTo({
          top: 0,
          behavior: 'smooth',
      });
    
      const interval = setTimeout(() => {
       setSuccess('')
    
      }, 5000);
    
      return () => clearInterval(interval);
    
    };
  
    const [formData, setFormData] = useState({
      value: "",
      sliderVal: 50,
      name: "",
      email: "",
      contact: "",
      address: "",
      section: "Kitchen",
      others: "",
      method: "Elevator",
      date: null,
      floor: null,
      postcode: "",
      city: "",
      description: "",
      orderstatus: "incomplete",
    });
    const navigate = useNavigate();
    const id = localStorage.getItem("edit_id");
  
    useEffect(() => {
      fetch(
        `https://admin.entsorg-meister.de/api/edit?id=${localStorage.getItem(
          "edit_id"
        )}`
      )
        .then((response) => response.json())
        .then((data) => {
          var DateObj = new Date(data.date).toString();
                        let selectDate = moment(DateObj).format("YYYY-MM-DD");
                     data.date=selectDate
              
                        
          setFormData({
            ...formData,
            value: data.value,
            sliderVal: data.sliderVal,
            name: data.name,
            email: data.email,
            contact: data.contact,
            address: data.address,
            section: data.section,
            others: data.others,
            method: data.method,
            date: data.date,
            floor: data.floor,
            postcode: data.postcode,
            city: data.city,
            description: data.description,
          });
          setLoading(false)
       
        })
       
          
        .catch((error) => console.log(error));
    }, []);
  
    async function handleSubmit(event) {
      event.preventDefault();
    }
   
  
    async function handleInfo(event) {
      const response = await fetch(
        "https://admin.entsorg-meister.de/api/updateorder",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            value: formData.value,
            sliderVal: formData.sliderVal,
            name: formData.name,
            email: formData.email,
            contact: formData.contact,
            address: formData.address,
            section: formData.section,
            others: formData.others,
            method: formData.method,
            floor: formData.floor,
            date: formData.date,
            postcode: formData.postcode,
            city: formData.city,
            description: formData.description,
            orderstatus: "complete",
            stage: 0,
            cost: null,
          }),
        }
      );
  
      const data = await response.json();
  
      if (data.status === 'ok') {
        goToTop()
        setSuccess("success")
      }
      else{
        goToTop()
        setSuccess("error")
      }
    }





 
useEffect(() => {
    console.log(localStorage.getItem('viewNewRequestId'))
    fetch(`${config.baseUrl}/api/viewNewReq?id=${localStorage.getItem('viewNewRequestId')}`)
    .then(response => response.json())
    .then((data) =>{
      setOrderData(data)

        console.log(data)
      setEmail(data.email)
        setName(data.name)
        setValue(data.value)
        setSliderValue(data.sliderVal)
        setEmail(data.email)
        setContact(data.contact)
        setSection(data.section)
        setAddress(data.address)
        setOthers(data.others)
        setMethod(data.method)
        setDate(data.date)
        setFloor(data.floor)
        setPostcode(data.postcode)
        setCity(data.city)
        setDescription(data.description)
        setOrderStatus(data.orderstatus)
      
        setLoading(false)

    })
    .catch((error)=> console.log(error))
  },[])

 

 
  return (

    
    <div className="flex h-screen overflow-hidden">

{/* Sidebar */}
<Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

{/* Content area */}
<div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

  {/*  Site header */}
  <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

  <main  >
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

      <HeadBar title={"Neue Anfrage bearbeiten"} description={"Individuelle Anfrage bearbeiten"}/>


 

      </div>
     
      <EditForm formDetails={orderData}/>

   
   </main>

  

 </div>
</div>
);
}

export default EditNewRequest