import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import toast, { Toaster } from 'react-hot-toast';

//Defaults
import HeadBar from "../partials/HeadBar";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import config from "../config/config";

function AllBlogs() {
  const componentRef = useRef(null);
  const navigate = useNavigate();


  //Default
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [itemId, setItemId] = useState(null);

  //Default functions
  const [count, setCount] = useState(0);
  const [blogs, setBlogs] = useState([]);
  const [push, setPush] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const url = config.baseUrl;
    fetch(`${url}/api/blogs/read`)
      .then((response) => response.json())
      .then((data) => {
        setBlogs(data);
        const length = data.length;
        setCount(length);
      });
  }, [push, load]);

  
  const successToast = (text) => toast.success(text);
  const errorText = (text) => toast.error(text);

  const onView = (id) => {
    localStorage.setItem('viewblogId', id)
    navigate('/view-blog')
  }

  
  const modalRef = useRef(null);

  const showModal = (id) => {
    modalRef.current.classList.remove('hidden');
    modalRef.current.classList.add('flex');

    setItemId(id);
  };

  const hideModal = () => {
    modalRef.current.classList.remove('flex');
    modalRef.current.classList.add('hidden');
  };

  
  const handleDelete = () => {
    onDelete(itemId);
    hideModal();
  };

  async function onDelete(id) {
    const url = config.baseUrl;
    const response = await fetch(`${url}/api/blog/remove`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
      }),
    });

    const data = await response.json();

    if (data.status === "ok") {
      successToast("Artikel wurde gelöscht.")

      setBlogs(
        blogs.filter((val) => {
          return blogs._id !== id;
        })
      );
      setPush(!push);
      setLoad(!load);
    }
    else{
      errorText("Artikel konnte nicht gelöscht werden.")

    }
  }
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main ref={componentRef}>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <HeadBar
              title={"Alle Blogs"}
              description={"Blogs anzeigen, bearbeiten und löschen"}
            />
          </div>
          <div><Toaster/></div>

<div id="deleteModal"
 tabindex="-1"
  aria-hidden="true"
  ref={modalRef}
   className="hidden overflow-y-auto overflow-x-hidden w-screen h-screen fixed  z-50 justify-center items-center md:inset-0 ">
    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
        <div className="relative p-4 text-center bg-white rounded-lg shadow-md border border-slate-200 sm:p-5 ">
            <button type="button" onClick={hideModal} className="text-gray-500 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="deleteModal">
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                <span className="sr-only">Close modal</span>
            </button>
            <svg className="text-slate-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
            <p className="mb-4 text-slate-800 ">Sind Sie sicher, dass Sie diese Anfrage löschen möchten?</p>
            <div className="flex justify-center items-center space-x-4">
                <button onClick={hideModal} data-modal-toggle="deleteModal" type="button" className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border-2   border-slate-300 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 ">
                Nein
                </button>
                <button onClick={handleDelete} className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 ">
                Ja, ich bin mir sicher
                </button>
            </div>
        </div>
    </div>
</div>
          {blogs ? <></> : null}

          <section class="pt-[30px]">
            <div class="px-4 mx-auto max-w-screen-xl lg:px-6">
              <div class=" mx-auto max-w-screen-md text-center mb-3"></div>
              {count === 0 ? (
                <>
                  {" "}
                  <img
                    className="block mx-auto"
                    src="https://entsorg-meistet-bucket.fra1.digitaloceanspaces.com/em%2Fno-blogs.png"
                    alt="No Blogs"
                  />
                  <p className="md:text-[28px]  text-[18px] mt-2 text-center text-slate-400 font-semibol ">
                    Noch keine Blogs!
                  </p>
                </>
              ) : (
                <div class="grid gap-8 lg:grid-cols-2">
                  {blogs.map((blog, index) => (
                    <article class="p-6 bg-white rounded-lg border border-gray-200 shadow-md ">
                      <div class="flex justify-between items-center mb-5 text-gray-500">
                        <span class="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded ">
                          <svg
                            class="mr-1 w-3 h-3"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z"
                              clip-rule="evenodd"
                            ></path>
                            <path d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z"></path>
                          </svg>
                          Article
                        </span>
                        <span class="text-sm">
                          Veröffentlicht am {blog.createdOn.substr(0, 10)}
                        </span>
                      </div>
                      <h2 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                        <a href="#">{blog.subheading}</a>
                      </h2>
                      <p class="mb-5 font-light text-gray-500 ">
                        {blog.paragraphs[0].text
                          .split(" ")
                          .slice(0, 15)
                          .join(" ")}
                      </p>
                      <div class="flex justify-between items-center">
                        <div class="flex items-center space-x-4">
                          <button
                            onClick={() => showModal(blog._id)}
                            className="w-[100%] rounded px-[20px] mr-[10px] py-[6px] font-medium text-[16px] text-white bg-red-500  hover:bg-red-700"
                          >
                            Löschen
                          </button>
                          <button
                             onClick={() => onView(blog._id)}
                            className="w-[100%] rounded px-[20px] mr-[30px] py-[6px] font-medium text-[16px] text-white bg-blue-500  hover:bg-blue-700"
                          >
                            Sicht
                          </button>
                        </div>
                
                      </div>
                    </article>
                  ))}
                </div>
              )}
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}

export default AllBlogs;
