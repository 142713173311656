import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import {  useNavigate } from "react-router-dom"

import config from '../config/config';

const BlogEditor = () => {
  const formData = new FormData();

  
  const [title, setTitle] = useState('');
  const [metaTags, setMetaTags] = useState('');
  const [description, setDescription] = useState('');
  const [subheading, setSubheading] = useState('');
  const [paragraphs, setParagraphs] = useState([{ text: '' }]);
  const [image, setImage] = useState(null);
  const [dataUrl, setDataUrl] = useState(null);

  const notify = (text) => toast.success(text);
const navigate = useNavigate()
  const handleBlogSave = async(e) => {
    e.preventDefault()

    var url= `${config.baseUrl}/api/blogs/add`
    formData.append("title", title)
    formData.append("metaTags", metaTags)
    formData.append("metaDescription", description)
    formData.append("subheading", subheading)
    formData.append("paragraphs", paragraphs)
    formData.append("image", dataUrl)
    console.log("formdata", formData);

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        title: title,
        metaTags: metaTags,
        metaDescription: description,
        subheading: subheading,
        paragraphs:paragraphs,
        image: dataUrl
      }),
     
    })


    const data = await response.json()

    if (data.status === 'ok') {
      toast.success("Blog wurde gespeichert.")
      setTimeout(() => {
      navigate('/allblogs')
      }, 500);
    }
    notify("Blog wurde gespeichert.")
    
  };

  const handleAddParagraph = () => {
    toast.success("Absatz wurde hinzugefügt.")
    setParagraphs([...paragraphs, { text: '' }]);
  };

  
  const handleParagraphChange = (e, index) => {
    const newParagraphs = [...paragraphs];
    newParagraphs[index].text = e.target.value;
    setParagraphs(newParagraphs);
  };


  const handleImageChange = e => {
    const selectedImage = e.target.files[0];
    formData.append("image", e.target.files[0])

    setImage(e.target.files[0]);
    // create a new FileReader object
    const reader = new FileReader();

    // set up the reader to load the selected image
    reader.onload = () => {
      // update the src of the img element to the data URL generated by the reader
      const img = document.getElementById("dropzone-image");
      img.src = reader.result;
      setDataUrl(reader.result)
    };

    // read the selected image as a data URL
    reader.readAsDataURL(selectedImage);

    // set the state with the selected image
   
    notify('Bild wurde hinzugefügt.')
  };

  return (
    <div className="flex">
       <Toaster />
       <form>

     
      <div className="w-[700px] p-8   bg-white rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold mb-4">Erstellen Sie einen neuen Blog</h1>
        <input
          className="border p-2 mb-4 w-full"
          type="text"
          placeholder="Titel"
          value={title}
          required
          onChange={e => setTitle(e.target.value)}
        />
        <input
          className="border p-2 mb-4 w-full"
          type="text"
          placeholder="Meta-Keywords (durch Kommas getrennt)"
          value={metaTags}
          required
          onChange={e => setMetaTags(e.target.value)}
        />
        <input
          className="border p-2 mb-4 w-full"
          type="text"
          placeholder="Meta-Beschreibung"
          value={description}
          required
          onChange={e => setDescription(e.target.value)}
        />
      
      <input
          className="border p-2 mb-4 w-full"
          type="text"
          placeholder="Untertitel"
          value={subheading}
          required
          onChange={e => setSubheading(e.target.value)}
        />

{paragraphs.map((paragraph, index) => (
          <textarea
            key={index}
            className="border p-2 mb-4 w-full"
            placeholder="Absatz"
            required
            value={paragraph.text}
onChange={e => handleParagraphChange(e, index)}
/>
))}

<div className="flex items-center justify-center w-full mb-6">
{image && <img
          id="dropzone-image"
          required
          className="w-full h-32  object-cover"
          src={image ? URL.createObjectURL(image) : ""}
          alt=""
        />}
    <label for="dropzone-file" className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100">
 
        <div className="flex flex-col items-center justify-center pt-3 pb-3 mt-3">
            <svg aria-hidden="true" className="w-8 h-8 mb-2 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
            <p className="mb-2 text-sm px-5 text-gray-500 dark:text-gray-400"><span className="font-semibold">Zum Hochladen klicken</span> oder ziehen und ablegen</p>
            <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG oder GIF</p>
        </div>
  <input id="dropzone-file" 
        onChange={handleImageChange}
        type="file"  
        
        accept="image/png, image/jpeg, image/webp"
        className="hidden" />
    </label>
</div>

{(title!=="" && metaTags!=="" && description!=="" && paragraphs[0].text!==''  && image!==null)?
<button onClick={handleBlogSave}  className='w-[100%] rounded px-[30px] mr-[30px] py-[8px] font-semibold text-[18px] text-white bg-blue-500 hover:translate-y-1 hover:bg-blue-700'>Speichern</button>
:
<button disabled type='button'  className='w-[100%] rounded px-[30px] mr-[30px] py-[8px] font-semibold text-[18px] text-white bg-blue-700'>Felder sind leer</button>

}

</div>
</form>
<div className="fixed bottom-16 right-16 w-[300px] p-6 max-w-sm mx-auto bg-slate-700 border border-indigo-600/20 flex flex-col rounded shadow-lg">
<h2 className="text-xl text-white font-bold mb-4">Elemente hinzufügen</h2>
<button
       className="bg-white  text-slate-800 p-2 mb-4 rounded hover:scale-105"
       onClick={handleAddParagraph}
     >
Absatz hinzufügen
</button>

</div>
</div>
);
};

export default BlogEditor;

