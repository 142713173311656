import React, { useState, useEffect , useRef } from 'react';
import {  useNavigate } from "react-router-dom"
import toast, { Toaster } from 'react-hot-toast';

//Defaults
import HeadBar from '../partials/HeadBar';
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import config from '../config/config';

//PDF
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import Select from 'react-select'
import { TbFileInvoice } from "react-icons/tb";

import { FaChevronCircleDown } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';
import { BsCalendar } from 'react-icons/bs';
import { BsSearch } from 'react-icons/bs';

const options = [
  { value: '1', label: 'Älteste' },
  { value: '-1', label: 'Neueste' },
]
    
    
    function CompletedOrders() {
      const componentRef = useRef(null);
      const navigate = useNavigate();
    
      const [itemId, setItemId] = useState(null);
      const modalRef = useRef(null);

      //Table components
      const [orderData, setOrderData] = useState('')
      const [push, setPush] = useState(false)
      const [load, setLoad] = useState(false)
      const [search, setSearch] = useState('')
      const [sort, setSort] = useState(-1)


      const [skip, setSkip] =useState(0)
      const [success, setSuccess] = useState('')

      //Default
      const [isOpen, setIsOpen] = useState(false);
      const [sidebarOpen, setSidebarOpen] = useState(false);
      const [startDate, setStartDate] = useState(null);
      const [endDate, setEndDate] = useState(null);
      const [selectedOption, setSelectedOption] = useState("");
    
      //Default functions
      const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
      };
    
      const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
      };
    
      const handleOptionChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setSort(selectedOption.value)
      };
    
      const convertDate = date => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = new Date(date).toLocaleDateString('de-DE', options);
        return formattedDate;
      };
    
      const handleToggle = (id) => {
        const current_row=document.getElementById(id)
          current_row.classList.toggle('expand')
          setIsOpen(!isOpen)
        
        }
    
        function exportCSV() {

          let  endDateTemp, startDateTemp
          if(startDate === null || endDate === null || startDate ==null || endDate == null){
           startDateTemp = 0
           endDateTemp = 0
        
          }
          else{
            startDateTemp = startDate
            endDateTemp = endDate
          }
              
          fetch(`${config.baseUrl}/api/acceptedoffers/export?search=${search}&sort=${sort}&skip=${skip}&datemin=${startDateTemp}&datemax=${endDateTemp}`)
          .then(response => response.blob())
          .then(blob => {
            toast.success("Datei wird heruntergeladen.");
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              const filename ="AbgeschlosseneBestellunge"+"_"+new Date().getDate()+"-" +(new Date().getMonth()+1)+"-" +new Date().getFullYear()+"_" +new Date().getHours()+"h-" +new Date().getMinutes()+"m-" +new Date().getSeconds()+"s.csv"
              link.setAttribute('download', filename);
              document.body.appendChild(link);
              link.click();
          });
        }
    

  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
  

    const interval = setTimeout(() => {
     setSuccess('')
     navigate("/sentoffers");

    }, 5000);
  
    return () => clearInterval(interval);
  
  };

  const view = (id) => {
    localStorage.setItem('viewCompletedId', id)
    navigate('/view-complete')
  }


  useEffect(() => {
    fetch(`${config.baseUrl}/api/completedOrders?search=${search}&sort=${sort}&skip=${skip}`)
    .then(response => response.json())
    .then((data) =>{ 
      // console.log(data)
      setOrderData(data)
    }
      )
  },[ load, push, search, sort, skip])

  const redirect_invoice_page = (id)=>{
    localStorage.setItem("user_invoice_id", id)
    navigate('/orderinvoices')
  }


  const sortOrder = (event) => {
    setSort(event.target.value)
  }

  
const showModal = (id) => {
  modalRef.current.classList.remove('hidden');
  modalRef.current.classList.add('flex');

  setItemId(id);
};

const hideModal = () => {
  modalRef.current.classList.remove('flex');
  modalRef.current.classList.add('hidden');
};

const handleDelete = () => {
  onDelete(itemId);
  hideModal();
};

  async function onDelete(id) { 

    const response = await fetch(`${config.baseUrl}/api/remove`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
       id
      }),
    })

    const data = await response.json()

    if (data.status === 'ok') {
      toast.success('Die Bestellung wurde erfolgreich gelöscht')

      setOrderData(orderData.filter((val)=>{
        return orderData._id!== id
      }))
      setPush(!push)
      setLoad(!load)

 
    }
    else{
      toast.error('Der Fehler konnte nicht gelöscht werden')

    }
  }
      
    
   
    
      return (
    
        
        <div className="flex h-screen overflow-hidden">
    
    {/* Sidebar */}
    <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
    
    {/* Content area */}
    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
    
      {/*  Site header */}
      <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
    
          <main  ref={componentRef}>
          <div className=" max-w-min-content mb-[100px]  col-span-full xl:col-span-12   ml-[45px]">

      <header className="px-5 py-6 border-b border-slate-100">
              <HeadBar
                title={"Abgeschlossene Bestellunge "}
                description={"Abgeschlossene Bestellungen auf einen Blick"}
                exportCSV={exportCSV}
                isVisible={true}
              />

<div><Toaster/></div>

<div id="deleteModal"
 tabindex="-1"
  aria-hidden="true"
  ref={modalRef}
   className="hidden overflow-y-auto overflow-x-hidden w-screen h-screen fixed  z-50 justify-center items-center md:inset-0 ">
    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
        <div className="relative p-4 text-center bg-white rounded-lg shadow-md border border-slate-200 sm:p-5 ">
            <button type="button" onClick={hideModal} className="text-gray-500 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="deleteModal">
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                <span className="sr-only">Close modal</span>
            </button>
            <svg className="text-slate-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
            <p className="mb-4 text-slate-800 ">Sind Sie sicher, dass Sie diese Bestellung löschen möchten?</p>
            <div className="flex justify-center items-center space-x-4">
                <button onClick={hideModal} data-modal-toggle="deleteModal" type="button" className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border-2   border-slate-300 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 ">
                Nein
                </button>
                <button onClick={handleDelete} className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 ">
                Ja, ich bin mir sicher
                </button>
            </div>
        </div>
    </div>
</div>
              
            </header>
            <div className="relative px-4 ">
              <div className="flex  justify-between items-center border rounded-md py-2 px-5 bg-slate-600 max-w-[1200px]">
                <div>
                  <BsCalendar className="text-white mr-4 inline-block" />
                  <input
                    type="date"
                    className="text-[14px] font-semibold text-slate-700 focus:outline-none border-0 p-2 w-40 rounded-md  border-gray-300"
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                  <p className="inline-block text-white px-4 ">to</p>
                  <input
                    type="date"
                    className="text-[14px] font-semibold text-slate-700 focus:outline-none border-0 p-2 w-40 rounded-md  border-gray-300"
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                </div>

                <div className="flex items-center">
                  <BsSearch className="text-white mr-3" />
                  <input
                    type="text"
                    className="rounded-sm shadow-lg text-slate-700 text-sm w-[200px] px-[15px] mr-8 py-2 inline-block rounded mr-4 focus:none"
                    placeholder="Suche nach Feldern"
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                  />

                  <Select
                    className="sm:w-[200px]  text-[12px] shadow-lg  shadow-lg text-slate-600 sm:text-sm text-[12px] rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-[200px] "
                    options={options}
                    value={selectedOption}
                    onChange={handleOptionChange}
                    placeholder="Anfragen filtern"


                  />
                </div>
              </div>
            </div>
      
    
         
 
      <div className="p-3">
    

        {/* Table */}
        <div className="overflow-x-auto"   >


{/* </select> */}

{/* </div> */}
<table className="table-auto bg-white border shadow-sm w-[100%] max-w-[1200px] ">
            
            {/* Table header */}
            <>
            <thead className="text-xs font-semibold uppercase text-slate-600 bg-slate-200">
              <tr>
             
             
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Name</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Kosten</div>
                </th>
               
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Telefon</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Email</div>
                </th>
             
                
                         
                               
                                         
                                            
                                          
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Sicht</div>
                </th>                                
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">Rechnungen</div>
                </th>                                
                                              
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left">	Löschen</div>
                </th>                                
                                        
                <th className="p-2 whitespace-nowrap">
                  <div className="font-bold text-left"></div>
                </th>                                
              </tr>
            </thead>
            </>

            <tbody className="text-sm divide-y divide-slate-100">
              {orderData!== '' &&
                orderData.map(order => {
                  return (
                    <>
                 
                    <React.Fragment>
                    <tr className='tr-main w-[100]' key={order._id}>
                   
                 
                         
                      <td className="p-2 whitespace-nowrap">
                          <div className="font-semibold text-slate-800">{ (order.firstName).charAt(0).toUpperCase() + (order.firstName).slice(1).toLowerCase()+" "+(order.lastName).charAt(0).toUpperCase() + (order.lastName).slice(1).toLowerCase() }</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                          <div className="font-semibold text-slate-800">{order.cost} €</div>
                      </td>
                    
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{order.phone}</div>
                      </td> 
                    
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{order.email}</div>
                      </td> 
                   
                     
                     
                      <td className="p-2 whitespace-nowrap">
                                  <div className="text-left">
                                    <button
                                      className="bg-gray-200 inline-flex items-center gap-2 rounded-md px-4 py-2 text-sm text-gray-500  hover:bg-gray-300 transition-all hover:text-gray-800 focus:relative"
                                      onClick={() => view(order._id)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="h-4 w-4"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                        />
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                      </svg>
                                      Sicht
                                    </button>
                                  </div>
                                </td>

                    

                 

                                <td className="p-2 whitespace-nowrap">
                                  <div className="text-left">
                                  <button
                                      className="bg-green-500 inline-flex items-center gap-2 rounded-md px-4 py-2 text-sm text-white  hover:bg-green-800 transition-all hover:text-gray-300 focus:relative"
                                      onClick={() =>
                                        redirect_invoice_page(order._id)
                                      }                                    >
                                    <TbFileInvoice  className="text-white" />
                                    Benutzer
                                    </button>
                                  </div>
                                </td>

                                <td className="p-2 whitespace-nowrap">
                                  <div className="text-left">
                                    <button
                                      onClick={() => showModal(order._id)}
                                      className="inline-flex items-center gap-2 border-2 rounded-md bg-red-500 px-4 py-2 text-sm text-white hover:text-slate-200 hover:bg-red-600 transition-all shadow-sm focus:relative"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="h-4 w-4"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                        />
                                      </svg>
                                      Löschen
                                    </button>
                                  </div>
                                </td>
                      
                      <td className="p-2 whitespace-nowrap">
                        <button onClick={()=>{handleToggle(order._id)} } className="text-left">
                        <FaChevronCircleDown className={`text-[25px] ${isOpen ? 'rotate' : 'rotate-alt'}`}/>                           {/* <img className='w-[20px]' src="./images/expandtoggle.jpg" alt="" /> */}
                        </button>
                      </td>
                      
                    </tr>
                    <tr className='w-[100] expand' id={order._id}>
                    
                      <td className="p-2 whitespace-nowrap">
                      <small className="text-[13px] text-left font-semibold">Ausweis</small>

                        <div className="text-left">EM-{order._id}</div>
                      </td>

                      <td className="p-2 whitespace-nowrap">
                      <small className="text-left text-[13px] font-semibold">Amount Paid(User)</small>

                        <div className="text-left">{order.cost*order.userper/100} €</div>
                      </td> 

                  
                    
                      {/* <td className="p-2 whitespace-nowrap">
                      <small className="text-[13px] text-left font-semibold">Subunternehmer-ID</small>

                        <div className="text-left">{order.subcontractor.id}</div>
                      </td> */}
                    
                      <td className="p-2 whitespace-nowrap">
                      <small className="text-[13px] text-left font-semibold">Location</small>

                        <div className="text-left">{order.location}</div>
                      </td>

                      <td className="p-2 whitespace-nowrap">
                      <small className="text-[13px] text-left font-semibold">Postcode</small>

                        <div className="text-left">{order.postcode}</div>
                      </td>
                  
            
                     
                      
                    
                      
                      <td className="p-2 whitespace-nowrap">
                      <small className="text-left font-semibold text-[13px]">Created on</small>

                        <div className="text-left">{convertDate(order.createdon)} </div>
                      </td> 
                      
                    </tr>
                    

                    </React.Fragment>
                    </>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>

       </main>
    
      
    
     </div>
    </div>
    );
    }
    
    export default CompletedOrders
