import React, { useState, useEffect , useRef } from 'react';
import toast, { Toaster } from "react-hot-toast";
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import config from '../config/config';

import HeadBar from '../partials/HeadBar';

function ViewSubcontractor() {

  const componentRef = useRef(null);


  const [isOpen, setIsOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
const [user, setUser] = useState('')
const [name, setName] = useState('')
const [loading, setLoading] = useState(true)


function exportCSV() {


  fetch(`${config.baseUrl}/api/sub/view/export?id=${localStorage.getItem('viewSubId')}`)
  .then(response => response.blob())
  .then(blob => {
    toast.success("Datei wird heruntergeladen.");

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      const filename ="SubunternehmerAnzeigen"+"_"+new Date().getDate()+"-" +(new Date().getMonth()+1)+"-" +new Date().getFullYear()+"_" +new Date().getHours()+"h-" +new Date().getMinutes()+"m-" +new Date().getSeconds()+"s.csv"
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
  });
}



const handleObj = (arr) => {
  const values = arr.map(obj => obj.value).join(', ');
  console.log("values", values)
  return values
  
  }
 
useEffect(() => {

    console.log(localStorage.getItem('viewSubId'))
    fetch(`${config.baseUrl}/api/sub/view?id=${localStorage.getItem('viewSubId')}`)
    .then(response => response.json())
    .then((data) =>{
        console.log(data)
        setUser(data)
        setName(data.firstName)
 
        setLoading(false)
    })
    .catch((error)=> console.log(error))
  },[])

 

 
  return (

    
    <div className="flex h-screen overflow-hidden">

{/* Sidebar */}
<Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
<div>
            <Toaster />
          </div>
{/* Content area */}
<div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

  {/*  Site header */}
  <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

  <main ref={componentRef}  >
    <div className="px-4 sm:px-6 lg:px-8 pt-8 w-full max-w-9xl mx-auto">

      <HeadBar 
      title={"Subunternehmer anzeigen"} 
      description={"Sehen Sie sich das Profil einzelner Subunternehmer an"}
      exportCSV={exportCSV}
      isVisible={true}/>


 
      </div>
   
   
{loading && <p className='ml-[300px] p-[30px] font-bold text-[30px] text-blue-400'>Lade Date <img className='inline-block' src="./images/spin.gif" alt="" /></p>
}

{ !loading && 

<div className='bg-white w-[90%] h-[100%] p-[25px] shadow-lg m-[30px] border-2 border-grey ml-[40px] '>

<div className="px-[20px] py-[10px] font-normal text-sm">
<label className="text-slate-700 inline-block w-[170px]  font-semibold">Ausweis : </label>
<span className=''>{"EM-"+user._id}</span>
</div>
<hr />
<div className="px-[20px] py-[10px] font-normal text-sm">
    <label className="text-slate-700 inline-block w-[170px]  font-semibold">Vorname : </label>
    <span className=''>{user.firstName}</span>
   </div>
   <hr />

<div className="px-[20px] py-[10px] font-normal text-sm">
    <label className="text-slate-700 inline-block w-[170px]  font-semibold">Nachname : </label>
    <span className=''>{user.lastName}</span>
   </div>
   <hr />
   <div className="px-[20px] py-[10px] font-normal text-sm">
    <label className="text-slate-700 inline-block w-[170px]  font-semibold">Unternehmen : </label>
    <span className=''>{user.company}</span>
   </div>
   <hr />
   <div className="px-[20px] py-[10px] font-normal text-sm">
   <label className="text-slate-700 inline-block w-[170px]  font-semibold">Telefon : </label>
    <span>{user.phone}</span>
   </div>
   <hr />
   <div className="px-[20px] py-[10px] font-normal text-sm">
   <label className="text-slate-700 inline-block w-[170px]  font-semibold">Email : </label>
    <span>{user.email}</span>
   </div>
   <hr />
   <div className="px-[20px] py-[10px] font-normal text-sm">
   <label className="text-slate-700 inline-block w-[170px]  font-semibold">Postleitzahl : </label>
    <span>{user.postcode}</span>
   </div>
   <hr />
   <div className="px-[20px] py-[10px] font-normal text-sm">
   <label className="text-slate-700 inline-block w-[170px]  font-semibold">Standort : </label>
    <span>{user.location}</span>
   </div>
   <div className="px-[20px] py-[10px] font-normal text-sm">
   <label className="text-slate-700 inline-block w-[170px]  font-semibold">Straße: </label>
    <span>{user.street} </span>
   </div>
   <hr />
   <div className="px-[20px] py-[10px] font-normal text-sm">
   <label className="text-slate-700 inline-block w-[170px]  font-semibold">VAT ID: </label>
    <span>{user.vat}</span>
   </div>
 
   <hr />



</div>}
   </main>

  







 </div>
</div>
);
}

export default ViewSubcontractor