import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Select from "react-select";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import config from "../config/config";

import HeadBar from "../partials/HeadBar";
import { toast } from "react-hot-toast";

const LocationOptions = [
  { label: "Berlin", value: "Berlin" },
  { label: "Hamburg", value: "Hamburg" },
  { label: "München", value: "München" },
  { label: "Köln", value: "Köln" },
  { label: "Stuttgart", value: "Stuttgart" },
  { label: "Düsseldorf", value: "Düsseldorf" },
  { label: "Leipzig", value: "Leipzig" },
  { label: "Dortmund", value: "Dortmund" },
  { label: "Essen", value: "Essen" },
  { label: "Bremen", value: "Bremen" },
  { label: "Dresden", value: "Dresden" },
  { label: "Hannover", value: "Hannover" },
  { label: "Nürnberg", value: "Nürnberg" },
  { label: "Duisburg", value: "Duisburg" },
  { label: "Bochum", value: "Bochum" },
  { label: "Wuppertal", value: "Wuppertal" },
  { label: "Bielefeld", value: "Bielefeld" },
  { label: "Bonn", value: "Bonn" },
  { label: "Münster", value: "Münster" },
];

function AddSubcontractor() {
  const { t } = useTranslation();

  const subjectRef = useRef(null);

  useEffect(() => {
    subjectRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const navigate = useNavigate();
  const componentRef = useRef(null);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedLocationOption, setSelectedLocationOption] = useState("");

  const [sub, setSub] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    company: "",
    location: "Berlin",
    street: "",
    postcode: "",
    phone: "",
    radius:null,
    vat: "",
  });

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [success, setSuccess] = useState("");

  const handleLocationChange = (selectedOption) => {
    setSelectedLocationOption(selectedOption);
  };

  async function handleSubmit(event) {
    event.preventDefault();
  }

  async function handleInfo(event) {
    const url = config.baseUrl;
    const response = await fetch(`${url}/api/sub/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName: sub.firstName,
        lastName: sub.lastName,
        email: sub.email,
        password: sub.password,
        company: sub.company,
        street: sub.street,
        postcode: sub.postcode,
        location: selectedLocationOption.value,
        phone: sub.phone,
        radius: sub.radius,
        vat: sub.vat,
      }),
    });

    const data = await response.json();

    if (data.status === "ok") {
      toast.success("Ein neuer Unterauftragnehmer wurde erstellt.")
      goToTop();
      setSuccess("success");
      navigate("/subcontractors");
    } else {
toast.error("Ein Fehler ist aufgetreten.")
      goToTop();
      setSuccess("error");
    }
  }

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main ref={componentRef}>
          <span  ref={subjectRef}  ></span>
          <div className=" max-w-min-content mb-[100px]  col-span-full xl:col-span-12   ml-[45px]">
            <HeadBar 
            title={"Subunternehmer hinzufügen"}
             description={"Fügen Sie ein Subunternehmerkonto hinzu. Eine E-Mail mit den Anmeldeinformationen wird an den Subunternehmer gesendet."} 
             isVisible={false}
            />

            <div className="w-[70%]  ml-[20px] shadow-black/20 shadow-lg form px-[40px] py-[40px] bg-white rounded-[10px]">
         
              <form action="" onSubmit={handleSubmit}>
                {success === "success" && (
                  <div className="mb-[20px]">
                    <p className="bg-blue-100 border-[1px] border-solid border-blue-500 w-[100%] px-[30px] py-[10px] ">
                      Ein neuer Unterauftragnehmer wurde erstellt und
                      Kontoanmeldeinformationen wurden per E-Mail gesendet.
                    </p>
                  </div>
                )}
                {success === "error" && (
                  <div className="mb-[20px]">
                    <p className="bg-red-100 border-[1px] border-solid border-red-500 w-[100%] px-[30px] py-[10px] ">
                      {t("error-occured")}
                    </p>
                  </div>
                )}
                <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                  <label
                    className="bg-slate-700 text-white font-medium  px-[15px] py-[8px] w-[300px]"
                    htmlFor="Company Name"
                  >
                    Vorname *
                  </label>
                  <input
                    className="w-[100%] inline px-[20px] bg-white"
                    type="name"
                    name="name"
                    id=""
                    onChange={(event) =>
                      setSub({ ...sub, firstName: event.target.value })
                    }
                    value={sub.firstName}
                  />
                </div>

                <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                  <label
                    className="bg-slate-700 text-white font-medium  px-[15px] py-[8px] w-[300px]"
                    htmlFor="Company Name"
                  >
                    Nachname 
                  </label>
                  <input
                    className="w-[100%] inline px-[20px] bg-white"
                    type="text"
                    name="company"
                    id=""
                    onChange={(event) =>
                      setSub({ ...sub, lastName: event.target.value })
                    }
                    value={sub.lastName}
                  />
                </div>

                <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                  <label
                    className="bg-slate-700 text-white font-medium  px-[15px] py-[8px] w-[300px]"
                    htmlFor="Company Name"
                  >
                    Email *
                  </label>
                  <input
                    className="w-[100%] inline px-[20px] bg-white"
                    type="text"
                    name="company"
                    id=""
                    onChange={(event) =>
                      setSub({ ...sub, email: event.target.value })
                    }
                    value={sub.email}
                  />
                </div>

                <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                  <label
                    className="bg-slate-700 text-white font-medium  px-[15px] py-[8px] w-[300px]"
                    htmlFor="Company Name"
                  >
                    Passwort *
                  </label>
                  <input
                    className="w-[100%] inline px-[20px] bg-white"
                    type="text"
                    name="company"
                    id=""
                    onChange={(event) =>
                      setSub({ ...sub, password: event.target.value })
                    }
                    value={sub.password}
                  />
                </div>

                <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                  <label
                    className="bg-slate-700 text-white font-medium  px-[15px] py-[8px] w-[300px]"
                    htmlFor="Company Name"
                  >
                    Name der Firma *
                  </label>
                  <input
                    className="w-[100%] inline px-[20px] bg-white"
                    type="text"
                    name="company"
                    id=""
                    onChange={(event) =>
                      setSub({ ...sub, company: event.target.value })
                    }
                    value={sub.company}
                  />
                </div>

                <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                  <label
                    className="bg-slate-700 text-white font-medium  px-[15px] py-[8px] w-[300px]"
                    htmlFor="Company Name"
                  >
                    {t("location")} *
                  </label>
                  <Select
                    className="w-[100%] bg-white    text-gray-800 sm:text-sm text-[12px] font-medium rounded-lg focus:ring-blue-500 focus:border-blue-500 block  "
                    options={LocationOptions}
                    value={selectedLocationOption}
                    onChange={handleLocationChange}
                    placeholder="Ort auswählen"
                  />
                </div>
                <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                  <label
                    className="bg-slate-700 text-white font-medium  px-[15px] py-[8px] w-[300px]"
                    htmlFor="Company Name"
                  >
                    Straße *
                  </label>
                  <input
                    className="w-[100%] inline px-[20px] bg-[#f7feff]"
                    type="text"
                    name="company"
                    id=""
                    onChange={(event) =>
                      setSub({ ...sub, street: event.target.value })
                    }
                    value={sub.street}
                  />
                </div>


                <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                  <label
                    className="bg-slate-700 text-white font-medium  px-[15px] py-[8px] w-[300px]"
                    htmlFor="Company Name"
                  >
                    {t("postcode")}
                  </label>
                  <input
                    className="w-[100%] inline px-[20px] bg-white"
                    type="text"
                    name="company"
                    id=""
                    onChange={(event) =>
                      setSub({ ...sub, postcode: event.target.value })
                    }
                    value={sub.postcode}
                  />
                </div>

                <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                  <label
                    className="bg-slate-700 text-white font-medium  px-[15px] py-[8px] w-[300px]"
                    htmlFor="Company Name"
                  >
                    {t("phone")} *
                  </label>
                  <input
                    className="w-[100%] inline px-[20px] bg-white"
                    type="text"
                    name="company"
                    id=""
                    onChange={(event) =>
                      setSub({ ...sub, phone: event.target.value })
                    }
                    value={sub.phone}
                  />
                </div>

                <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                  <label
                    className="bg-slate-700 text-white font-medium  px-[15px] py-[8px] w-[300px]"
                    htmlFor="Company Name"
                  >
                    {t("vatid")} *
                  </label>
                  <input
                    className="w-[100%] inline px-[20px] bg-white"
                    type="text"
                    name="company"
                    id=""
                    onChange={(event) =>
                      setSub({ ...sub, vat: event.target.value })
                    }
                    value={sub.vat}
                  />
                </div>

                <div className="form-grp bg-[#f7feff] w-[100%] border-[1px] border-indigo/10 shadow-indigo/20 mb-[10px] flex">
                  <label
                    className="bg-slate-700 text-white font-medium  px-[15px] py-[8px] w-[300px]"
                    htmlFor="Radius of work"
                  >
                    Arbeitsradius (in km) *
                  </label>
                  <input
                    className="w-[100%] inline px-[20px] bg-[#f7feff]"
                    type="number"
                    min={5}
                    name="company"
                    id=""
                    onChange={(event) =>
                      setSub({ ...sub, radius: event.target.value })
                    }
                    value={sub.radius}
                  />
                </div>

          


                {(  sub.company === "" ||
                    sub.street === "" ||
                    sub.postcode === "" ||
                    sub.location === "" ||
                    sub.phone === null ||
                    sub.phone === "" ||
                    sub.vat === "" ||
                    sub.radius === "" ||
                    sub.firstName === "") ? 

<button
disabled 

className="mt-6 cursor-not-allowed text-md ml-3 w-full  object-contain px-6 py-2.5 font-semibold text-white bg-gray-500 border-b-4 border-gray-400 rounded shadow-indigo-500 shadow-sm "
>
Profil sichern </button>

:
<button

onClick={handleInfo}
className="text-md ml-3  w-full hover:translate-y-[2px] transition  object-contain px-8 py-2.5 font-semibold text-white bg-green-500 border-b-4 border-green-800 rounded shadow-green-500 shadow-sm hover:border-green-600"
>

Profil sichern
</button>
}


              </form>
              <hr />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default AddSubcontractor;
