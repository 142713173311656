import React, { useState, useEffect , useRef } from 'react';
import toast, { Toaster } from "react-hot-toast";

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import config from '../config/config';
import HeadBar from '../partials/HeadBar';




function ViewRevenue() {

  const componentRef = useRef(null);


  const [isOpen, setIsOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
const [user, setUser] = useState('')
const [name, setName] = useState('')



const [loading, setLoading] = useState(true)


function exportCSV() {
  fetch(`${config.baseUrl}/api/viewrevenue/export?id=${localStorage.getItem('viewRevenueId')}`)
  .then(response => response.blob())
  .then(blob => {
    toast.success("Datei wird heruntergeladen.");

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      const filename ="UmsatzdetailsAnzeigen"+"_"+new Date().getDate()+"-" +(new Date().getMonth()+1)+"-" +new Date().getFullYear()+"_" +new Date().getHours()+"h-" +new Date().getMinutes()+"m-" +new Date().getSeconds()+"s.csv"
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
  });
}

const handleObj = (arr) => {
  const values = arr.map(obj => obj.value).join(', ');
  console.log("values", values)
  return values
  
  }
 
  
useEffect(() => {

    fetch(`${config.baseUrl}/api/viewrevenue?id=${localStorage.getItem('viewRevenueId')}`)
    .then(response => response.json())
    .then((data) =>{
        console.log(data)
        setUser(data)
        setName(data.name)
 
        setLoading(false)
    })
    .catch((error)=> console.log(error))
  },[])

 

 
  return (

    
    <div className="flex h-screen overflow-hidden">

{/* Sidebar */}
<Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

{/* Content area */}
<div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

  {/*  Site header */}
  <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

  <main ref={componentRef}  >
    <div className="px-4 sm:px-6 lg:px-8 pt-8 w-full max-w-9xl mx-auto">
<Toaster />
      <HeadBar 
      title={"Akzeptierte Angebote anzeigen"} 
      description={"Sehen Sie sich einzelne angenommene Angebote an"}
      exportCSV={exportCSV}
      isVisible={true}/>


 
      </div>
   
   
{loading && <p className='ml-[300px] p-[30px] font-bold text-[30px] text-blue-400'>Lade Date <img className='inline-block' src="./images/spin.gif" alt="" /></p>
}

{ !loading && 

<div className='bg-white w-[90%] h-[100%] p-[25px] shadow-lg m-[30px] border-2 border-grey ml-[40px] '>

<div className="px-[20px] py-[10px] font-normal text-sm">
<label className="text-slate-700 inline-block w-[170px]  font-semibold">Konto-ID : </label>
<span className=''>{"EM-"+user.account_id}</span>
</div>
<hr />
<div className="px-[20px] py-[10px] font-normal text-sm">
<label className="text-slate-700 inline-block w-[170px]  font-semibold">Name : </label>
<span className=''>{user.name }</span>
</div>
<hr />
<div className="px-[20px] py-[10px] font-normal text-sm">
<label className="text-slate-700 inline-block w-[170px]  font-semibold">Schild : </label>
<span className=''>{user.tag}</span>
</div>
<hr />
<div className="px-[20px] py-[10px] font-normal text-sm">
<label className="text-slate-700 inline-block w-[170px]  font-semibold">Menge : </label>
<span className=''>{user.amount} €</span>
</div>
<hr />
<div className="px-[20px] py-[10px] font-normal text-sm">
<label className="text-slate-700 inline-block w-[170px]  font-semibold">Durchbezahlt : </label>
<span className=''>{user.source} </span>
</div>
<hr />
<div className="px-[20px] py-[10px] font-normal text-sm">
<label className="text-slate-700 inline-block w-[170px]  font-semibold">Telefon : </label>
<span className=''>{user.phone} </span>
</div>
<hr />


<div className="px-[20px] py-[10px] font-normal text-sm">
<label className="text-slate-700 inline-block w-[170px]  font-semibold">Email : </label>
<span className=''>{user.email}</span>
</div>
<hr />

<div className="px-[20px] py-[10px] font-normal text-sm">
<label className="text-slate-700 inline-block w-[170px]  font-semibold">Transaktions-ID : </label>
<span className=''>{user.txn_id}</span>
</div>
<hr />

<div className="px-[20px] py-[10px] font-normal text-sm">
<label className="text-slate-700 inline-block w-[170px]  font-semibold">Registriert auf : </label>
<span className=''>{user.registeredOn}</span>
</div>
<hr />

<div className="px-[20px] py-[10px] font-normal text-sm">
<label className="text-slate-700 inline-block w-[170px]  font-semibold">TnC-Akzeptanz : </label>
<span className=''>{user.tncUser ?<> Akzeptiert <img className='w-[20px] inline-block mr-3 ml-1' src="./images/status_true.png" /></> : <>Nicht akzeptiert <img className='w-[25px] inline-block mr-3 ml-3' src="./images/status_false.png" /></>}</span>

</div>
<hr />

<div className="px-[20px] py-[10px] font-normal text-sm">
<label className="text-slate-700 inline-block w-[200px]  font-semibold">TnC-Annahmezeitstempel : </label>
<span className=''>{user.tncUserTimestamp}</span>
</div>
<hr />




</div>}
   </main>

  







 </div>
</div>
);
}

export default ViewRevenue