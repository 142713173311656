import React, { useState, useEffect } from "react";
import {
  BsFillInfoCircleFill,
  BsFillPhoneLandscapeFill,
  BsPeopleFill,
  BsFileTextFill,
  BsHouseFill,
  BsFillCreditCardFill,
} from "react-icons/bs";

import makeAnimated from 'react-select/animated';
import { FaTruck, FaBuilding } from "react-icons/fa";
import { MdElevator, MdAvTimer, MdDateRange } from "react-icons/md";
import { HiTemplate } from "react-icons/hi";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";

import config from "../config/config";



const multipleOptions = [
  { value: "Mobel", label: "Mobel" },
  { value: "Schrankinhalt", label: "Schrankinhalt" },
  { value: "Elektrogerate", label: "Elektrogerate" },
  { value: "Gartenabfall", label: "Gartenabfall" },
  { value: "Sonstiges", label: "Sonstiges" },
];

const elevatorOptions = [
  { value: "Ja", label: "Ja" },
  { value: "Nein", label: "Nein" },
];

const areaOptions = [
  { value: "Bis zu 25 m.sq.", label: "Bis zu 25 m.sq." },
  { value: "26 bis 50 m.sq.", label: "26 bis 50 m.sq." },
  { value: "51 bis 75 m.sq.", label: "51 bis 75 m.sq." },
  { value: "76 bis 100 m.sq.", label: "76 bis 100 m.sq." },
  { value: "101 bis 125 m.sq.", label: "101 bis 125 m.sq." },
  { value: "126 bis 150 m.sq.", label: "126 bis 150 m.sq." },
  { value: "Mehr als 150 m.sq.", label: "Mehr als 150 m.sq." },
];

const salutations = [
  { value: "Herr", label: "Herr" },
  { value: "Frau", label: "Frau" },
];

const timeOptions = [
  { value: "Morgens", label: "Morgens (06:00 - 12:00)" },
  { value: "Mittags", label: "Mittags (12:00 - 16:00)" },
  { value: "Abends", label: "Abends (12:00 - 16:00)" },
  { value: "Flexibel", label: "Flexibel (12:00 - 16:00)" },
];

const placeOptions = [
  { value: "Haus", label: "Haus" },
  { value: "Keller", label: "Keller" },
  { value: "Wohnung", label: "Wohnung" },
  { value: "Dachboden", label: "Dachboden" },
  { value: "Garage", label: "Garage" },
  { value: "Garten", label: "Garten" },
  { value: "Büro", label: "Büro" },
  { value: "Lager", label: "Lager" },
  { value: "Ladengeschäft", label: "Ladengeschäft" },
  { value: "Schrott", label: "Schrott" },
  { value: "Bauschutt", label: "Bauschutt" },
];

const floorOptions = [
  { value: "Keller", label: "Keller" },
  { value: "Erdgeschoss", label: "Erdgeschoss" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "10+", label: "10+" },
];

const Form = () => {
  const [showalert, setShowalert] = useState(false);
  const [selectedMultipleOption, setSelectedMultipleOption] = useState([]);
  const [selectedPlaceOption, setSelectedPlaceOption] = useState("");
  const [selectedFloor, setSelectedFloor] = useState("");
  const [selectedElevator, setSelectedElevator] = useState("");
  const [selectedSalutation, setSelectedSalutation] = useState("");
  const [area, setArea] = useState(null);
  const [description, setDescription] = useState("");
  const [contact_time, setContact_time] = useState("");
  const [contact_date, setContact_date] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [street, setStreet] = useState("");
  const [location, setLocation] = useState("");
  const [postcode, setPostcode] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const [file, setFile] = useState([]);
  const [stage, setStage] = useState(0);
  const [noOfFiles, setNoOfFiles] = useState(0);
  const [images, setImages] = useState([]);

  const animatedComponents = makeAnimated();

  const notify = (text) => toast.error(text);

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [stage]);


  const handleChange = (selectedOption) => {
    setSelectedMultipleOption(selectedOption);
  };

  const handlePlaceChange = (selectedOption) => {
    setSelectedPlaceOption(selectedOption);
  };
  const handleFloorChange = (selectedOption) => {
    setSelectedFloor(selectedOption);
  };

  const handleElevatorChange = (selectedOption) => {
    setSelectedElevator(selectedOption);
  };

  const handleArea = (selectedOption) => {
    setArea(selectedOption);
  };

  const handleTime = (selectedOption) => {
    console.log(selectedOption);
    setContact_time(selectedOption);
  };

  const handleMessageChange = (event) => {
    // 👇️ access textarea value
    setDescription(event.target.value);
    console.log(event.target.value);
  };

  const handleDate = (e) => {
    setContact_date(e.target.value);
    console.log(contact_date);
  };

  const handleSalutation = (selectedOption) => {
    setSelectedSalutation(selectedOption);
  };

  
    


  const handleFile = (e) => {
    const fileList = e.target.files;
    const selectedFiles = Array.from(e.target.files);

    if (file.length + selectedFiles.length > 6) {
      notify("Sie können nur maximal 6 Dateien hochladen");
      return;
    }

    setFile((prevFiles) => [...prevFiles, ...selectedFiles]);
    setNoOfFiles((prevNoOfFiles) => prevNoOfFiles + selectedFiles.length);

    const imageArray = Array.from(fileList).map(file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    });

    Promise.all(imageArray).then((imageDataArray) => {
      setImages((prevImages) => [...prevImages, ...imageDataArray]);
    });
  };

  const handleRemove = (index) => {
    const updatedFiles = [...file];
    updatedFiles.splice(index, 1);

    const updatedImages = [...images];
    updatedImages.splice(index, 1);

    setFile(updatedFiles);
    setNoOfFiles((prevNoOfFiles) => prevNoOfFiles - 1);
    setImages(updatedImages);
  };

  async function prev(event) {
    event.preventDefault();
    setStage(stage - 1);
  }

  async function next(event) {
    event.preventDefault();
    if (stage === 0) {
      if (
        selectedPlaceOption === "" ||
        area === null ||
        selectedMultipleOption === [] ||
        selectedFloor === "" ||
        selectedElevator === "" ||
        description === ""
      ) {
        setShowalert(true);
        setTimeout(() => {
          setShowalert(false);
        }, 5000);
      } else {
        setStage(stage + 1);
      }
    }
    if (stage === 1) {
      if (contact_date === null || contact_time === null) {
        console.log(contact_date, contact_time);
        setShowalert(true);
        setTimeout(() => {
          setShowalert(false);
        }, 2000);
      } else {
        console.log(contact_date, contact_time);

        setStage(stage + 1);
      }
    }

    if (stage === 2) {
      if (
        selectedSalutation === "" ||
        firstName === "" ||
        lastName === "" ||
        email === "" ||
        phone === ""
      ) {
        setShowalert(true);
        setTimeout(() => {
          setShowalert(false);
        }, 2000);
      } else {
        setStage(stage + 1);
      }
    }
  }

  async function handleInfo(event) {
    if (stage === 3) {
      if (street === "" || location === "") {
        setShowalert(true);
        setTimeout(() => {
          setShowalert(false);
        }, 2000);
      } else {
        setStage(4)
        var url = `${config.baseUrl}/api/v1/order`;

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            place: selectedPlaceOption.value,
            objects: selectedMultipleOption,
            hasElevator: selectedElevator.value,
            area: area.value,
            description: description,
            floor: selectedFloor.value,
            date: contact_date,
            time: contact_time.value,
            salutation: selectedSalutation.value,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            street: street,
            houseNo: houseNo,
            postcode: postcode,
            location: location,
            images: images,
            orderstatus: "complete",
            stage: 0,
            cost: null,
          }),
        });

        const data = await response.json();

        if (data.status === "ok") {
          console.log(data);
          setStage(5);
        }
      }
    }
  }

  return (
    <>
      <section className="md:px-[90px] px-[30px] mb-[62px] relative flex justify-center">
        <div className="w-[80vw] min-w-[300p] max-w-[700px] h-[20px]  absolute top-[120px] ">
          <div className="flex justify-between items-center">
            {stage < 5 ? (
              <>
                <BsFillInfoCircleFill
                  className=" z-5"
                  size="30px"
                  color="#1371EF"
                />
                <FaTruck className="z-5" color="#1371EF" size="30px" />
                <BsFillCreditCardFill
                  className="z-5"
                  size="30px"
                  color="#1371EF"
                />
              </>
            ) : (
              <>
                <BsFillInfoCircleFill
                  className=" z-5"
                  size="30px"
                  color="#4CEC2D"
                />
                <FaTruck className="z-5" color="#4CEC2D" size="30px" />
                <BsFillCreditCardFill
                  className="z-5"
                  size="30px"
                  color="#4CEC2D"
                />
              </>
            )}
            {stage === 0 && (
              <div className=" w-[80vw] min-w-[300p] max-w-[700px] left-[30px] absolute z-0 block h-[10px] w-[0] bg-[#1371EF] rounded-[20px] "></div>
            )}

            {stage === 1 && (
              <div className=" w-[80vw] min-w-[300p] max-w-[700px] left-[30px] absolute z-0 block h-[10px] w-[calc(50%-30px)] bg-[#1371EF] rounded-[20px] "></div>
            )}

            {stage === 2 && (
              <div className=" w-[80vw] min-w-[300p] max-w-[700px] left-[30px] absolute z-0 block h-[10px] w-[calc(100%-30px)] bg-[#1371EF] rounded-[20px] "></div>
            )}
            {stage === 3 || stage ===4 && (
              <div className=" w-[80vw] min-w-[300p] max-w-[700px] left-[30px] absolute z-0 block h-[10px] w-[calc(100%-30px)] bg-[#1371EF] rounded-[20px] "></div>
            )}

            {stage === 5 && (
              <div className=" w-[80vw] min-w-[300p] max-w-[700px] left-[30px] absolute z-0 block h-[10px] w-[calc(100%-30px)] bg-[#4CEC2D] rounded-[20px] "></div>
            )}
          </div>
          <div className="flex justify-between items-center ">
            <span className="text-gray-800 font-medium sm:text-[14px] text-[12px] mt-2">
              Das Objekt{" "}
            </span>
            <span className="text-gray-800 font-medium sm:text-[14px] text-[12px] mt-2">
              Die Gegenstände{" "}
            </span>
            <span className="text-gray-800 font-medium sm:text-[14px] text-[12px] mt-2">
              Die Buchung{" "}
            </span>
          </div>
        </div>

        {stage === 0 && (
          <div className=" mb-[120px] mt-[200px]">
            <form className="relative text-slate-800 shadow-lg rounded-lg border-2 border-orange-300 bg-white sm:w-[500px] w-[310px] sm:px-[30px] px-[15px] py-[30px]">
              {showalert ? (
                <div className=" text-center  mb-2 ">
                  <div
                    className="p-2  items-center  text-white bg-red-600 border-red-400 border-2 leading-none lg:rounded-full flex lg:inline-flex"
                    role="alert"
                  >
                    <span className="flex rounded-full bg-red-400  text-white  px-2 py-1 text-sm font-semibolds mr-3">
                      Aufmerksamkeit!
                    </span>
                    <span className="font-semibold mr-2 text-sm text-left flex-auto">
                      Alle Felder sind erforderlich
                    </span>
                  </div>
                </div>
              ) : null}
              <div className="flex justify-between items-center mb-5">
                <BsHouseFill className="mr-3" color="orange" size="1.5em" />
                <Select
                  className="sm:w-[400px] bg-gray-50 shadow-lg  border border-gray-300 text-gray-800 sm:text-sm text-[12px] font-medium rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[300px]  "
                  options={placeOptions}
                  value={selectedPlaceOption}
                  onChange={handlePlaceChange}
                  placeholder="Was möchten Sie emptrümpeln?"
                  components={animatedComponents}
                />
              </div>

              <div className="flex justify-between items-center mb-5">
                <HiTemplate className="mr-3" color="orange" size="1.5em" />
                <Select
                  className="sm:w-[400px] bg-gray-50 shadow-lg  border border-gray-300 text-gray-800 sm:text-sm text-[12px] font-medium rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[300px]  "
                  isMulti
                  options={multipleOptions}
                  value={selectedMultipleOption}
                  onChange={handleChange}
                  placeholder="Welche Gegenstände sollten entsorgt werden?"
                  components={animatedComponents}

                />
              </div>

              <div className="flex justify-between items-center mb-5">
                <FaBuilding className="mr-3" color="orange" size="1.5em" />
                <Select
                  className="sm:w-[400px] bg-gray-50 shadow-lg  border border-gray-300 text-gray-800 sm:text-sm text-[12px] font-medium rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[300px]  "
                  options={floorOptions}
                  value={selectedFloor}
                  onChange={handleFloorChange}
                  placeholder="Wählen Sie Ihren Boden."
                  components={animatedComponents}

                  
                />
              </div>

              <div className="flex justify-between items-center mb-5">
                <BsFillPhoneLandscapeFill
                  className="mr-3"
                  color="orange"
                  size="1.5em"
                />

                <Select
                  className="sm:w-[400px] bg-gray-50 shadow-lg  border border-gray-300 text-gray-800 sm:text-sm text-[12px] font-medium rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[300px]  "
                  options={areaOptions}
                  value={area}
                  onChange={handleArea}
                  placeholder="Wie viele m.sq. hat das Objekt?"
                  components={animatedComponents}

                />
              </div>

              <div className="flex justify-between items-center mb-5">
                <MdElevator className="mr-3" color="orange" size="1.5em" />

                <Select
                  className="sm:w-[400px] bg-gray-50 shadow-lg  border border-gray-300 text-gray-800 sm:text-sm text-[12px] font-medium rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[300px]  "
                  options={elevatorOptions}
                  value={selectedElevator}
                  onChange={handleElevatorChange}
                  placeholder="Gibt es einen Fahrstuhl?"
                  components={animatedComponents}

                />
              </div>

              <div className="flex justify-between items-start mb-5">
                <BsFileTextFill className="mr-3 " color="orange" size="1.5em" />

                <textarea
                  required
                  onChange={handleMessageChange}
                  value={description}
                  id="message"
                  rows="3"
                  className=" sm:w-[400px]  block p-2.5 w-[300px] sm:text-sm text-[12px] text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Zusätzliche Beschreibung Ihrer Bedürfnisse"
                />
              </div>
            </form>
          </div>
        )}

        {stage === 1 && (
          <div className=" mb-[120px] mt-[200px]">
            <form className="shadow-lg rounded-lg border-2 border-orange-300 bg-white sm:w-[500px] w-[310px] sm:px-[30px] px-[15px] py-[30px]">
              {showalert ? (
                <div className=" text-center  mb-2 ">
                  <div
                    className="p-2  items-center  text-white bg-red-600 border-red-400 border-2 leading-none lg:rounded-full flex lg:inline-flex"
                    role="alert"
                  >
                    <span className="flex rounded-full bg-red-400  text-white  px-2 py-1 text-sm font-semibolds mr-3">
                      Aufmerksamkeit!
                    </span>
                    <span className="font-semibold mr-2 text-sm text-left flex-auto">
                      Alle Felder sind erforderlich
                    </span>
                  </div>
                </div>
              ) : null}
              <label className="text-sm block font-semibold ml-[20px] mb-3">
                {" "}
                An welchem Tag soll die Entrumpelung stattfinden?
              </label>
              <div className="flex justify-between items-center mb-5">
                <MdDateRange className="mr-3" color="orange" size="1.5em" />

                <div className="relative">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>

                  <input
                    type="date"
                    value={contact_date}
                    onChange={handleDate}
                    className="sm:w-[400px]  w-[245px]  bg-gray-100 shadow-lg  border border-gray-300 text-gray-900 text-sm font-medium rounded-lg  focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  "
                    placeholder="Select date"
                  ></input>
                </div>
              </div>
              <div className="flex justify-between items-center mb-5">
                <MdAvTimer className="mr-3" color="orange" size="1.5em" />
                <Select
                  className="sm:w-[400px] bg-gray-100 shadow-lg  border border-gray-300 text-gray-900 sm:text-sm text-[12px] font-medium rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[300px]  "
                  options={timeOptions}
                  value={contact_time}
                  onChange={handleTime}
                  placeholder="Wählen Sie die gewünschte Zeit aus"
                  components={animatedComponents}

                />
              </div>
            </form>
          </div>
        )}
        <Toaster />

        {stage === 2 && (
          <div className=" mb-[120px] mt-[200px]">
            <p className="text-[18px] font-bold mb-4">
              Wie dürfen wir dich ansprechen?
            </p>
            <div className="flex justify-between items-center mb-5">
              <form className="shadow-lg rounded-lg border-2 border-orange-400 bg-gray-50 sm:w-[500px] w-[310px] sm:px-[30px] px-[15px] py-[30px]">
                {showalert ? (
                  <div className=" text-center  mb-2 ">
                    <div
                      className="p-2  items-center  text-white bg-red-600 border-red-400 border-2 leading-none lg:rounded-full flex lg:inline-flex"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-red-400  text-white  px-2 py-1 text-sm font-semibolds mr-3">
                        Aufmerksamkeit!
                      </span>
                      <span className="font-semibold mr-2 text-sm text-left flex-auto">
                        Alle Felder sind erforderlich
                      </span>
                    </div>
                  </div>
                ) : null}
                <div className="flex justify-between items-center mb-5">
                  <BsPeopleFill className="mr-3 " color="orange" size="1.5em" />
                  <Select
                    className="sm:w-[400px] bg-gray-100 shadow-lg  border border-gray-300 text-gray-900 sm:text-sm text-[12px] font-medium rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[300px]"
                    options={salutations}
                    value={selectedSalutation}
                    onChange={handleSalutation}
                    placeholder="Anrede"
                    components={animatedComponents}

                  />
                </div>
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="relative z-0 mb-6 w-full group">
                    <input
                      type="name"
                      value={firstName}
                      maxLength={20}
                      required
                      onChange={(e) => {
                        const capitalizedValue =
                          e.target.value.charAt(0).toUpperCase() +
                          e.target.value.slice(1).toLowerCase();
                        setFirstName(capitalizedValue);
                      }}
                      name="floating_first_name"
                      id="floating_first_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    />
                    <label
                      for="floating_first_name"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Vorname
                    </label>
                  </div>
                  <div className="relative z-0 mb-6 w-full group">
                    <input
                      type="name"
                      value={lastName}
                      maxLength={20}
                      required
                      onChange={(e) => {
                        const capitalizedValue =
                          e.target.value.charAt(0).toUpperCase() +
                          e.target.value.slice(1).toLowerCase();
                        setLastName(capitalizedValue);
                      }}
                      name="floating_last_name"
                      id="floating_last_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />

                    <label
                      for="floating_last_name"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Nachname
                    </label>
                  </div>

                  <div className="relative z-0 mb-6 w-full group">
                    <input
                      type="email"
                      value={email}
                      maxLength={30}
                      required
                      onChange={(e) => {
                        const capitalizedValue = e.target.value.toLowerCase();
                        setEmail(capitalizedValue);
                      }}
                      name="floating_email"
                      id="floating_email"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    />
                    <label
                      for="floating_email"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      E-Mail
                    </label>
                  </div>
                  <div className="relative z-0 mb-6 w-full group">
                    <input
                      type="tel"
                      required
                      minLength={10}
                      maxLength={14}
                      value={phone}
                      onChange={(event) => setPhone(event.target.value)}
                      name="floating_phone"
                      id="floating_phone"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    />
                    <label
                      for="floating_phone"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Telefon
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}

        {stage === 3 && (
          <div className=" mb-[120px] mt-[200px]">
            <p className="text-[18px] font-bold mb-4">Wo ist das Objekt?</p>
            <div className="flex justify-between items-center mb-5">
              <form className="shadow-lg relative rounded-lg border-2 border-orange-400 bg-gray-50 sm:w-[500px] w-[310px] sm:px-[30px] px-[15px] py-[30px]">
                {showalert ? (
                  <div className=" text-center  mb-2 ">
                    <div
                      className="p-2  items-center  text-white bg-red-600 border-red-400 border-2 leading-none lg:rounded-full flex lg:inline-flex"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-red-400  text-white  px-2 py-1 text-sm font-semibolds mr-3">
                        Aufmerksamkeit!
                      </span>
                      <span className="font-semibold mr-2 text-sm text-left flex-auto">
                        Alle Felder sind erforderlich
                      </span>
                    </div>
                  </div>
                ) : null}
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="relative z-0 mb-6 w-full group">
                    <input
                      type="text"
                      name="floating_first_name"
                      maxLength={40}
                      value={street}
                      onChange={(e) => {
                        const capitalizedValue =
                          e.target.value.charAt(0).toUpperCase() +
                          e.target.value.slice(1).toLowerCase();
                        setStreet(capitalizedValue);
                      }}
                      id="floating_first_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=""
                      required
                    />
                    <label
                      for="floating_first_name"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Straße
                    </label>
                  </div>
                  <div className="relative z-0 mb-6 w-full group">
                    <input
                      type="text"
                      value={houseNo}
                      maxLength={15}
                      onChange={(event) => setHouseNo(event.target.value)}
                      name="floating_last_name"
                      id="floating_last_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=""
                      required
                    />
                    <label
                      for="floating_last_name"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Hausnummer
                    </label>
                  </div>

                  <div className="relative z-0 mb-6 w-full group">
                    <input
                      type="text"
                      maxLength={5}
                      minLength={5}
                      value={postcode}
                      onChange={(event) => setPostcode(event.target.value)}
                      name="floating_email"
                      id="floating_email"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=""
                      required
                    />
                    <label
                      for="floating_email"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Postleitzahl
                    </label>
                  </div>

                  <div className="relative z-0 mb-6 w-full group">
                    <input
                      type="text"
                      maxLength={20}
                      value={location}
                      onChange={(e) => {
                        const capitalizedValue =
                          e.target.value.charAt(0).toUpperCase() +
                          e.target.value.slice(1).toLowerCase();
                        setLocation(capitalizedValue);
                      }}
                      name="floating_phone"
                      id="floating_phone"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=""
                      required
                    />
                    <label
                      for="floating_phone"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Ort
                    </label>
                  </div>
                </div>
                <p className="text-[18px] font-bold mb-1">
                  Möchtest Du Fotos des Objekts hochladen?
                </p>
                <div>
                  {noOfFiles === 0 && (
                    <small className=" text-[14px] text-red-500 font-semibold mb-2  block self-start">
                      {" "}
                      No image has been selected yet!
                    </small>
                  )}

                  <div className="flex items-center justify-center w-full">
                    <label
                      for="dropzone-file"
                      className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100"
                    >
                      <div className="flex flex-col items-center justify-center pt-3 pb-3 mt-3">
                        <svg
                          aria-hidden="true"
                          className="w-8 h-8 mb-2 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          ></path>
                        </svg>
                        <p className="mb-2 text-sm px-5 text-gray-500 dark:text-gray-400">
                          <span className="font-semibold">
                            Zum Hochladen klicken
                          </span>{" "}
                          oder ziehen und ablegen
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          SVG, PNG, JPG oder GIF
                        </p>
                      </div>
                      <input
                        id="dropzone-file"
                        onChange={handleFile}
                        type="file"
                        multiple
                        className="hidden"
                        accept=".png, .jpg, .jpeg"
                      />
                    </label>
                  </div>
                </div>

                {noOfFiles > 0 && (
                  <div className="border-slate-300/70 border-dashed rounded border-2 p-3 m-2">
                    <div className="grid grid-cols-4 gap-4 sm:grid-cols-3">
                      {file.map((file) => (
                        <div key={file.name} className="relative">
                          <img
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                            className="border-slate-200/80 border-2 w-full h-20 object-cover"
                          />
                          <button
                            className="hover:bg-red-600 hover:scale-110 absolute top-0 transition-all right-0 w-5 h-5 bg-red-500 rounded-full flex items-center justify-center"
                            onClick={() => handleRemove(file)}
                          >
                            <svg
                              className="w-4 h-4 text-white"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

              </form>
            </div>
          </div>
        )}

        {stage === 4 && 
          <div className="mb-[120px]  mt-[200px]">
            <div class="lds-ripple"><div></div><div></div></div>
            <p className="sm:text-[18px] text-[12px] textslate-60 font-bold mb-4">

              </p>
          </div>
          }

        {stage === 5 && (
          <div className="mb-[120px]  mt-[200px]">
            <p className="sm:text-[18px] text-[12px] text-[#4CEC2D] font-bold mb-4">
              <img
                className="sm:w-[60px] w-[30px] inline-block mr-1"
                src="./images/success.gif"
              ></img>
              Ihre Bestellung wurde erfolgreich registriert.
            </p>
            <div className=" mb-[30px] mt-[30px]">
              <form className="shadow-lg rounded-lg border-2 border-orange-300 bg-white sm:w-[500px] w-[310px] sm:px-[30px] px-[15px] py-[30px]">
                {showalert ? (
                  <div className=" text-center  mb-2 ">
                    <div
                      className="p-2  items-center  text-white bg-red-600 border-red-400 border-2 leading-none lg:rounded-full flex lg:inline-flex"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-red-400  text-white  px-2 py-1 text-sm font-semibolds mr-3">
                        Aufmerksamkeit!
                      </span>
                      <span className="font-semibold mr-2 text-sm text-left flex-auto">
                        Alle Felder sind erforderlich
                      </span>
                    </div>
                  </div>
                ) : null}

                <p className="text-[16px] text-[#444] font-semibold mb-4">
                  Unsere Agenten werden sich bald mit Ihnen in Verbindung setzen
                </p>

                <img
                  width="60px"
                  className="block mx-auto w-[300px]"
                  src="./images/confirm_order.svg"
                ></img>

                <div className="relative z-0 mb-6 w-full group"></div>
              </form>
            </div>
          </div>
        )}
      </section>

      {stage < 4 ? (
        <div className=" shadow  bg-white fixed bottom-[0px] w-[calc(100vw-16rem)] flex justify-center align-center right-[0px] border-2  border-gray-200 items-center h-[120px] z-50">
          {stage === 0 && (
            <button
              type="button"
              className="text-white text-[25px] bg-gradient-to-br from-orange-500 to-[orange] hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-semibold rounded-lg text-sm  sm:w-[300px] w-[150px] py-5 text-center mr-2 my-2"
              onClick={next}
            >
              Weiter
            </button>
          )}

          {stage === 1 && (
            <>
              <button
                type="button"
                className="text-white text-[25px] bg-gradient-to-br from-blue-500 to-[indigo] hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-semibold rounded-lg text-sm sm:w-[300px] w-[150px] py-5 text-center mr-10 my-2"
                onClick={prev}
              >
                Zurück
              </button>
              <button
                type="button"
                className="text-white text-[25px] bg-gradient-to-br from-orange-500 to-[orange] hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-semibold rounded-lg text-sm sm:w-[300px] w-[150px] py-5 text-center mr-2 my-2"
                onClick={next}
              >
                Weiter
              </button>
            </>
          )}

          {stage === 2 && (
            <>
              <button
                type="button"
                className="text-white text-[25px] bg-gradient-to-br from-blue-500 to-[indigo] hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-semibold rounded-lg text-sm  sm:w-[300px] w-[150px] py-5 text-center mr-10 my-2"
                onClick={prev}
              >
                Zurück
              </button>
              <button
                type="button"
                className="text-white text-[25px] bg-gradient-to-br from-orange-500 to-[orange] hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-semibold rounded-lg text-sm sm:w-[300px] w-[150px] py-5 text-center mr-2 my-2"
                onClick={next}
              >
                Weiter
              </button>
            </>
          )}

          {stage === 3 && (
            <>
              <button
                type="button"
                className="text-white text-[25px] bg-gradient-to-br from-blue-500 to-[indigo] hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-semibold rounded-lg text-sm sm:w-[300px] w-[150px] py-5 text-center mr-10 my-2"
                onClick={prev}
              >
                Einreichen
              </button>

              <button
                type="button"
                className="text-white text-[25px] bg-gradient-to-br from-orange-500 to-[orange] hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-semibold rounded-lg text-sm sm:w-[300px] w-[150px] py-5 text-center mr-2 my-2"
                onClick={handleInfo}
              >
                Weiter
              </button>

            </>
          )}
        </div>
      ) : null}
    </>
  );
};

export default Form;
