import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import HeadBar from "../partials/HeadBar";
import Form from "../partials/Form";

function AddOrder() {
  const subjectRef = useRef(null);

  useEffect(() => {
    subjectRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Welcome banner */}
            <HeadBar
              title={"Auftrag hinzufügen"}
              description={"Fügen Sie eine neue Bestellung von Grund auf hinzu"}
              isVisible={false}
            />

            <div ref={subjectRef}>
              <Form />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default AddOrder;
