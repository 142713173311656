import React, { useState, useEffect , useRef } from 'react';
import {  useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'


//Defaults
import HeadBar from '../partials/HeadBar';
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import config from '../config/config';

//PDF
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import Select from 'react-select'

import { FaChevronCircleDown } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';
import { BsCalendar } from 'react-icons/bs';
import { BsSearch } from 'react-icons/bs';

const options = [
  { value: '1', label: 'Älteste' },
  { value: '-1', label: 'Neueste' },
]
 
function ReviseCost() {
  const { t } = useTranslation()
  const navigate = useNavigate();
  //Table components

  //Default
  const [isOpen, setIsOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  

    
    const [cost, setCost] =useState(null)
    const [userper, setUserper] =useState(30)
    const [costRevisionLog, setCostRevisionLog] =useState('')
    const [id, setId] =useState('')
    const [revisionRemarks, setRevisionRemarks] =useState('')
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState('')
    const [showModal, setShowModal] = useState(false);


    const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });

    const interval = setTimeout(() => {
    setSuccess('')
    navigate("/sentoffers");
    
    }, 5000);

    return () => clearInterval(interval);

    };

    useEffect(() => {
    fetch(`${config.baseUrl}/api/getCost?id=${localStorage.getItem('revisionId')}`)
    .then(response => response.json())
    .then((data) => {
        setCost(data.cost)
        setUserper(data.userper)
        const str =`Costing has been revised from Cost: ${data.cost} Є, User Advance Percent: ${data.userper} % to `
        setCostRevisionLog(str)
        setLoading(false)
    })
    },[])



  useEffect(() => {
    setId(localStorage.getItem('revisionId'))
  },[])

 
    const handleRevision = () => {
    setShowModal(true)
    }

    async function handleRevisionSubmission(event) {
    setShowModal(false)
    let newDate =Date.now()
    const modifiedDate = new Date(newDate).getDate()+"/" + (new Date(newDate).getMonth() + 1) + "/" + new Date(newDate).getFullYear();

    const response = await fetch(`${config.baseUrl}/api/costrevision`, {
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({
        id:id,
        cost:cost,
        userper:userper,
        revisionRemarks:revisionRemarks,
        revisionDate:modifiedDate,
        costRevisionLog: costRevisionLog + `Cost: ${cost} Є, User Advance Percent: ${userper} % on ${modifiedDate}`
    }),
    }
    );

    const data = await response.json();

    if (data.status === 'ok') {
        goToTop()
        setSuccess("success")
    }
    else{
        goToTop()
        setSuccess("error")
    }
    }

   


  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main  >
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
    
    <HeadBar title={"Kosten überarbeiten"} description={"Überarbeiten Sie die Kosten nach der Änderung des Umfangs"}/>
        
    
    {showModal ?  <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                  Kosten überarbeiten
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                  Die Änderung der Kosten oder des Prozentsatzes des Benutzervorschusses würde dem Benutzer per E-Mail mitgeteilt, und es würde erneut ein Angebot an den Benutzer gesendet. Erst wenn der Nutzer zugestimmt hat, wird diese Bestellung weiterverfolgt.
                  <br></br>
                  <br></br>
                  </p>
                </div>
                <div class="mb-6 mx-[30px] ">
                <input 
                type="text"
                id="email" 
                onChange={(event) =>
                setRevisionRemarks(event.target.value)
                }
                value={revisionRemarks}
                class="bg-white border border-green-300 text-gray-900 text-sm rounded-lg focus:ring-red-800 focus:border-green-800 block w-full p-2.5 dark:bg-white dark:border-green-600 dark:placeholder-gray-400 dark:text-grey dark:focus:ring-green-800 dark:focus:border-green-500 border-green-500 border-2 text-grey" 
                placeholder="Put some extra remarks"
                required ></input>
    </div> 
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                
                
                
                <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                 {revisionRemarks.length <2 ? 
                 <button disabled
                    className="bg-green-800 text-[#999] active:bg-grey-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    
                  >
                    Confirm 
                  </button>
                  :
                  <button
                    className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                    onClick={handleRevisionSubmission}
                  >
                    Confirm 
                  </button>}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      : null}
<div className=' ml-[20px] bg-white shadow p-[40px]'>
      <h2 className='font-bold text-[#0e0e2b] text-[30px] mb-[1px]'>Kosten überarbeiten</h2>
    <p className=' text-[#888] text-[12px] mb-[30px] '>Eine Bestätigungs-E-Mail bezüglich der Überarbeitung wird an die E-Mail-Adresse des Benutzers gesendet</p>


    {loading && <p className='ml-[300px] p-[30px] font-bold text-[30px] text-blue-400'>{t('loading-data')} <img className='inline-block' src="./images/spin.gif" alt="" /></p>
}

{ !loading &&
 <div className="  w-[90vw] max-w-[800px] rounded text-[#333] bg-white   shadow-lg shadow-grey border-2 border-grey p-[45px]">




    <div class="parallel  justify-around items-center mb-[30px]">
      <form onSubmit={(e)=>{e.preventDefault()}}>
      {success==='success' && <div className='mb-[20px]'>
  <p className='bg-blue-100 border-[1px] border-solid border-blue-500 w-[100%] px-[30px] py-[10px] '>Angebot erfolgreich versendet! Sie werden in Kürze automatisch weitergeleitet</p>
</div>}
{success==='error' && <div className='mb-[20px]'>
  <p className='bg-red-100 border-[1px] border-solid border-red-500 w-[100%] px-[30px] py-[10px] '>Es ist ein Fehler aufgetreten. Bitte versuche es erneut</p>
</div>}
      <div className="form-box mb-[10px] flex">
     <input className='bg-white px-[10px]  py-[5px]  shadow-lg border-2 border-grey' type="number" min={30} required placeholder ="Cost" 
     onChange={event => setCost(event.target.value)} value={cost} />
      <label  className='w-[280px] px-[10px]  py-[5px] text-white bg-indigo-500'>Kosten Є</label>
      </div>
      <div className="form-box mb-[10px] flex">
     
      <select className="bg-white px-[10px] w-[220px] py-[5px] shadow-lg border-2 border-grey" required name="userper" defaultValue={userper} onChange={event => setUserper(event.target.value)} >
                <option  value="20">20 %</option>
                <option value="25">25 %</option>
                <option value="30" >30 %</option>
                <option value="35">35 %</option>
            </select>
    <label  className='w-[280px] px-[10px]  py-[5px] text-white bg-indigo-500'>Vorauskosten % (Benutzer)</label>
      </div>

       </form>
      </div>
      {(cost<1 ) ?  <button
   
      type="button"
      disabled
      className="cursor-not-allowed text-md  object-contain px-6 py-2 font-semibold text-white bg-gray-300 border-b-4 border-gray-400 rounded shadow-indigo-500 shadow-sm "
    >
      
      Beide Felder sind erforderlich  </button>
      :
      <button
      onClick={() => handleRevision()} 
      type="button"
      className=" text-md  object-contain px-6 py-2 font-semibold text-white hover:bg-green-600 hover:translate-y-1 transition-all bg-green-500 border-b-4 border-green-400 rounded shadow-green-500 shadow-sm "
    >
      
      Aktualisierte Kosten speichern    </button>

}
    

</div>}
</div>


</div>

        </main>

       

      </div>
    </div>
  );
}

export default ReviseCost;