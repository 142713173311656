import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, NavLink } from "react-router-dom";

//Defaults
import HeadBar from "../partials/HeadBar";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import config from "../config/config";

function ViewBlog() {
  const [blogPost, setBlogPost] = useState(null)
  const [sidebarOpen, setSidebarOpen] = useState(false);

    const styles = {
       width:"100%"
      };


  
  useEffect(() => {
    const url = config.baseUrl
    fetch(`${url}/api/blog/read?id=${localStorage.getItem("viewblogId")}`)
    .then(response => response.json())
    .then((data) => {
        setBlogPost(data)
    })
  },[])
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main >
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
    
    <HeadBar title={"Blog ansehen"}/>

    </div>
  <div class="flex justify-between px-4 mx-auto max-w-screen-xl py-[35px]">
    
    {blogPost ?  
    <article class="shadow-2xl border-2 border-gray-200 mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert p-[24px] bg-white">
          <header class="mb-4 lg:mb-6 not-format">
              <address class="flex items-center mb-6 not-italic">
                  <div class="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                      <img class="mr-4 w-16 h-16 rounded-full" src="./images/logo192.png" alt="Entsorg Meister"></img>
                      <div>
                          <p class="text-base font-light text-gray-500 dark:text-gray-400"> Entsorg Meister</p>
                          <p class="text-base font-light text-gray-500 dark:text-gray-400"><time pubdate datetime={blogPost.createdOn.substr(0, 10)} title="February 8th, 2022">Veröffentlicht am {blogPost.createdOn.substr(0, 10)}</time></p>
                      </div>
                  </div>
              </address>
              <h1 class="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl">{blogPost.subheading}</h1>
          </header>

      
          <figure className=' mb-[25px]'><img src={blogPost.image} alt="" style={styles}></img>

          </figure>
        
     
        {blogPost.paragraphs.map((item, index) => (
                 <p className="text-[#414141] text-md mb-[40px]">{item.text}</p>

      ))}

  
        
      </article>
      : null}

  </div>
</main>
      </div>
    </div>
  );
}

export default ViewBlog;
