import React, { useState, useEffect , useRef } from 'react';
import {  useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'

import toast, { Toaster } from "react-hot-toast";

//Defaults
import HeadBar from '../partials/HeadBar';
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import config from '../config/config';


function ManualUserPayments() {

  const navigate = useNavigate();


  //Default
  const [isOpen, setIsOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [success, setSuccess] = useState('')
  const [orderId, setOrderId] = useState('')
  const [name, setName] = useState('')
  const [cost, setCost] = useState('')
  const [userper, setUserper] = useState('')
  const [payable, setPayable] = useState('')
  const [loading, setLoading] = useState('')
  const [source, setSource] = useState('')
  const [transId, setTransId] = useState('')
  const [manualUserPayRemarks, setManualUserPayRemarks] = useState('')
  
  const notify = (text) => toast.success(text);

  const { t } = useTranslation()

  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
    
    const interval = setTimeout(() => {
        setSuccess('')
     
       }, 5000);

       return () => clearInterval(interval);

  };
  
const id=localStorage.getItem('editSub_id')


  useEffect(() => {
    fetch(`${config.baseUrl}/api/userDetails?id=${localStorage.getItem('manualPaymentId')}`)
    .then(response => response.json())
    .then((data) =>{
        console.log(data)
    setName(data.firstName+" " +data.lastName)
    setCost(data.cost)
    setUserper(data.userper)
    setUserper(data.section)
    setPayable(data.cost*data.userper/100)
    setLoading(false)

    })
    .catch((error)=> console.log(error))
  },[])

  useEffect(() => {
    setOrderId(localStorage.getItem('manualPaymentId'))
    console.log(orderId);

    })
  

  

  async function handleSubmit(event) { 
    event.preventDefault()
}


async function handleInfo(event) { 
    const response = await fetch(`${config.baseUrl}/api/order/user/updateManualPay`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: orderId,
        transId:transId,
        manualUserPayRemarks:manualUserPayRemarks,
        source: source
      }),
    })

    const data = await response.json()

    if (data.status === 'ok') {
        notify("Die Benutzerzahlung wurde registriert. Eine E-Mail wird zusammen mit einer Rechnung an den Benutzer gesendet.")
        goToTop()
        navigate('/acceptedoffers')
        setSuccess("success")
      }
      else{
        goToTop()
        setSuccess("error")
      }

  }
 


  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
<Toaster/>
        <main  >
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
    
    <HeadBar title={"Zahlungen manuell aktualisieren"} description={"Fügen Sie manuelle Zahlungen hinzu, um den Status zu aktualisieren"}/>
        <form  onSubmit={handleSubmit}>
   
   <div className=' ml-[20px] w-[90vw] max-w-[1000px] rounded text-[#333]  flex justify-start  p-[45px] bg-white shadow-lg border-2 border-grey '>
     <div>
     {loading && <p className='ml-[300px] p-[30px] font-bold text-[30px] text-blue-400'>{t('loading-data')} <img className='inline-block' src="./images/spin.gif" alt="" /></p>
}
{!loading &&   
<div className='w-[90vw]  max-w-[900px]'>
      {success==='success' && <div className='mb-[20px]'>
<p className='bg-blue-100 border-[1px] border-solid border-blue-500 w-[100%] px-[30px] py-[10px] '>Die Benutzerzahlung wurde manuell registriert. Eine E-Mail zusammen mit einer Rechnung wird an den Benutzer gesendet.</p>
</div>}
{success==='error' && <div className='mb-[20px]'>
<p className='bg-red-100 border-[1px] border-solid border-red-500 w-[100%] px-[30px] py-[10px] '>Es ist ein Fehler aufgetreten</p>
</div>}
  <div>

     
       <div className=" sm:overflow-hidden sm:rounded-md">
         <div className="space-y-6 bg-white px-4 py-5 sm:sp-6">
         <div className="col-span-6">
                   <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                   Vollständiger Name
                   </label>
                   <input
                     type="name"
                     name="name"
                     id="name"
                     disabled

                     onChange={event => setName(event.target.value)} value={name}
                     className="mt-1 block w-full rounded-md border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border text-[14px] px-6 py-2 mb-[3px] rounded block mb-10px"
                   />
                 </div>
                 <div className="bg-white ">
               <div className="grid grid-cols-6 gap-6">
          
               <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                   <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                   Gesamtkosten (Є)
                   </label>
                   <input
                     type="text"
                     disabled

                     onChange={event => setCost(event.target.value)} value={cost}

                     className="mt-1 block w-full rounded-md border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border text-[14px] px-6 py-2 mb-[3px] rounded block mb-10px"
                   />
                 </div>

                 <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                   <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                   Vom Benutzer zu zahlen (Є)
                   </label>
                   <input
                     type="text"
                     name="region"
                     id="region"
                     autoComplete="address-level1"
                     disabled
                      value={payable}

                     className="mt-1 block w-full rounded-md border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border text-[14px] px-6 py-2 mb-[3px] rounded block mb-10px"
                   />
                 </div>

               
                 </div>
                 </div>
               
               
                 <div className="col-span-6">
                   <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                   Quelle der manuellen Zahlung *
         </label>
                   <input
                     type="text"
                    placeholder='e.g., Erhalten per Banküberweisung / Scheck / Bargeld'
                     onChange={event => setSource(event.target.value)} value={source}
                     required
                     className="mt-1 block w-full rounded-md border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border text-[14px] px-6 py-2 mb-[3px] rounded block mb-10px"
                   />
                 </div>

                 <div className="col-span-6">
                   <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                   Transaktions-ID *
                   </label>
                   <input
                     type="text"
                    required
                     onChange={event => setTransId(event.target.value)} value={transId}

                     className="mt-1 block w-full rounded-md border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border-2 text-[14px] px-6 py-2 mb-[3px] rounded block mb-10px"
                   />
                 </div>
        

                 <div className="col-span-6">
                   <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                   Bemerkungen (Zusätzliche Referenz) *
                   </label>
                   <input
                     type="text"
                     required
                     onChange={event => setManualUserPayRemarks(event.target.value)} value={manualUserPayRemarks}

                     className="mt-1 block w-full rounded-md border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-[90%] border text-[14px] px-6 py-2 mb-[3px] rounded block mb-10px"
                   />
                 </div>
        
            

            



         

       
                 <div className="bg-gray-50 py-3 text-right sm:px-1">


               <button
                 type="submit"
                 disabled={manualUserPayRemarks=="" || transId=="" || source=="" }
                 onClick ={handleInfo}
                 className={` ${(manualUserPayRemarks=="" || transId=="" || source=="") ? 'cursor-not-allowed bg-slate-500 text-slate-100 ': 'hover:border-green-600 text-white bg-green-500 hover:translate-y-[2px] transition border-green-800 shadow-green-500'} text-md ml-3  w-full  object-contain px-8 py-2.5 font-semibold  border-b-4  rounded  shadow-sm `}
                 >

                 Rechnung aktualisieren und erstellen
               </button>
             </div>
            


             


   </div>
   </div>
   </div>
   </div> }
   </div>
 </div>
</form>
</div>

        </main>

       

      </div>
    </div>
  );
}

export default ManualUserPayments;