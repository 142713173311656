import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import config from "../config/config";
import HeadBar from "../partials/HeadBar";


function ViewNewRequest() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const componentRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [user, setUser] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);

  function exportCSV() {
    fetch(
      `${config.baseUrl}/api/viewnewrequest/export?id=${localStorage.getItem(
        "viewNewRequestId"
      )}`
    )
      .then((response) => response.blob())
      .then((blob) => {
        toast.success("Datei wird heruntergeladen.");

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        const filename =
          "ViewNeueAnfragen" +
          "_" +
          new Date().getDate() +
          "-" +
          (new Date().getMonth() + 1) +
          "-" +
          new Date().getFullYear() +
          "_" +
          new Date().getHours() +
          "h-" +
          new Date().getMinutes() +
          "m-" +
          new Date().getSeconds() +
          "s.csv";
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      });
  }

  const handleObj = (arr) => {
    const values = arr.map((obj) => obj.value).join(", ");
    console.log("values", values);
    return values;
  };

  useEffect(() => {
    console.log(localStorage.getItem("viewNewRequestId"));
    fetch(
      `${config.baseUrl}/api/viewNewReq?id=${localStorage.getItem(
        "viewNewRequestId"
      )}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setUser(data);
        setName(data.firstName);

        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main ref={componentRef}>
          <div className=" max-w-min-content mb-8 col-span-full xl:col-span-12   ml-[45px]">
            <HeadBar
              title={"Neue Anfrage anzeigen"}
              description={"Individuelle Anfrage ansehen"}
              exportCSV={exportCSV}
              isVisible={true}
            />
          </div>
          <div>
            <Toaster />
          </div>

          {loading && (
            <p className="ml-[300px] p-[30px] font-bold text-[30px] text-blue-400">
              Lade Date{" "}
              <img className="inline-block" src="./images/spin.gif" alt="" />
            </p>
          )}

          {!loading && (
            <div className="bg-white w-[90%] h-[100%] p-[25px] shadow-lg m-[30px] border-2 border-grey ml-[40px]">
              <div className="text-sm text-slate-700">
                <div className="px-[20px] py-[10px] font-normal text-sm">
                  <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                    Ausweis :
                  </label>
                  <span className="">{"EM-"+user._id}</span>
                </div>
                <hr />
                <div className="px-[20px] py-[10px] font-normal text-sm">
                  <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                    Anrede :
                  </label>
                  <span className="">{user.salutation}</span>
                </div>
                <hr />
                <div className="px-[20px] py-[10px] font-normal text-sm">
                  <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                    Vollständiger Name :{" "}
                  </label>
                  <span className="">
                    {user.firstName + " " + user.lastName}
                  </span>
                </div>
                <hr />
                <div className="px-[20px] py-[10px] font-normal text-sm">
                  <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                    Telefon :{" "}
                  </label>
                  <span className="">{user.phone}</span>
                </div>
                <hr />
                <div className="px-[20px] py-[10px] font-normal text-sm">
                  <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                    Email :{" "}
                  </label>
                  <span className="">{user.email} </span>
                </div>
                <hr />
                <div className="px-[20px] py-[10px] font-normal text-sm">
                  <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                    Bereich :{" "}
                  </label>
                  <span className="">{user.area} </span>
                </div>
                <hr />

                <div className="px-[20px] py-[10px] font-normal text-sm">
                  <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                    Objekte :{" "}
                  </label>
                  <span className="">{handleObj(user.objects)}</span>
                </div>
                <hr />
                <div className="px-[20px] py-[10px] font-normal text-sm">
                  <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                    Place :{" "}
                  </label>
                  <span className="">{user.place}</span>
                </div>
                <hr />

                <div className="px-[20px] py-[10px] font-normal text-sm">
                  <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                    Ort :{" "}
                  </label>
                  <span className="">{user.location}</span>
                </div>
                <hr />

                <div className="px-[20px] py-[10px] font-normal text-sm">
                  <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                    Straße :{" "}
                  </label>
                  <span className="">{user.street}</span>
                </div>
                <hr />

                <div className="px-[20px] py-[10px] font-normal text-sm">
                  <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                    Boden :{" "}
                  </label>
                  <span className="">{user.floor}</span>
                </div>
                <hr />

                <div className="px-[20px] py-[10px] font-normal text-sm">
                  <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                    Aufzug :{" "}
                  </label>
                  <span className="">{user.hasElevator}</span>
                </div>
                <hr />

                <div className="px-[20px] py-[10px] font-normal text-sm">
                  <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                    Straße Hausnr. :{" "}
                  </label>
                  <span className="">{user.houseNo}</span>
                </div>
                <hr />

                <div className="px-[20px] py-[10px] font-normal text-sm">
                  <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                    Datum :{" "}
                  </label>

                  <span>{user.date}</span>
                </div>
                <hr />

                <div className="px-[20px] py-[10px] font-normal text-sm">
                  <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                    Kontaktzeit :{" "}
                  </label>

                  <span>{user.time}</span>
                </div>
                <hr />

                <div className="px-[20px] py-[10px] font-normal text-sm">
                  <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                    Registriert auf :{" "}
                  </label>
                  <span className="">{user.createdon}</span>
                </div>
                <hr />

                <div className="px-[20px] py-[10px] font-normal text-sm">
                  <label className="text-slate-700 inline-block w-[170px]  font-semibold">
                    Beschreibung :{" "}
                  </label>
                  <span className="">{user.description}</span>
                </div>
                <hr />
              </div>
              {user.images ? (
                <>
                  <div className="px-[20px] py-[10px] font-normal text-sm mt-6">
                    <h3 className="text-[#33333] font-semibold text-3xl  ">
                      Images{" "}
                    </h3>
                  </div>
                  <div className="mt-6 grid grid-cols-2 md:grid-cols-4 gap-4">
                    {user.images.map((image, index) => (
                      <div
                        key={index}
                        className="flex justify-center items-center"
                      >
                        <img
                          src={image}
                          alt={`image-${index}`}
                          className="shadow-sm border rounded-sm border-slate-200 w-[200px] h-[200px] object-cover"
                        />
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
            </div>
          )}
        </main>
      </div>
    </div>
  );
}

export default ViewNewRequest;
