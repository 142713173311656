import React, {useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import config from '../config/config';

const Login = () => {

    const navigate = useNavigate();



    const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [err, setErr] = useState('')
  const [error, setError]=useState({
    emailColor:"neutral",
    emailError:"Gib deine E-Mail hier ein",
    emailPass:false,
    passColor:"neutral",
    passError:"Das Passwort sollte aus mehr als 8 Zeichen bestehen, mindestens ein Sonderzeichen und eine Zahl sind Pflicht.",
    passPass:false

})


function handlePass(e) { 
    setPassword(e.target.value)
    if(e.target.value===''){
        setError({...error, passError : "Das Passwort kann nicht gelöscht werden", passColor:"red", passPass:false})  
    }
    else if(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,36}$/.test(e.target.value)){
        setError({...error, passError : "Passwort ist gültig", passColor:"green",passPass:true})  
    }
    else{
        setError({...error, passError : "Das Passwort sollte aus mehr als 8 Zeichen bestehen, mindestens ein Sonderzeichen und eine Zahl sind Pflicht.", passColor:"red",passPass:false})  

    }  
}
  function handleEmail(e) { 
    setEmail(e.target.value)
    if(e.target.value===''){
        setError({...error, emailError : "E-Mail kann nicht ausgeblendet werden", emailColor:"red", emailPass:false})  
    }
    else if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/.test(e.target.value)){
        setError({...error, emailError : "E-Mail gültig sein", emailColor:"green",emailPass:true})  
    }
    else{
        setError({...error, emailError : "E-Mail sollte gültig sein", emailColor:"red",emailPass:false})  

    }  
}

	async function loginUser(event) {
		event.preventDefault()

		const response = await fetch(`${config.baseUrl}/api/login`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email,
				password
			}),
		})

		const data = await response.json()

		if (data.user) {
			localStorage.setItem('email', data.user) 
      console.log('success') 
      const str='Logging you in!';
      setErr(str)	
      navigate('/')
		} else {
      const str='Bitte überprüfen Sie Ihre E-Mail und Ihr Passwort'

      setErr(str)
		}
	}



  
  return (
    <div className="bg-[#232436] lg:flex overflow-hidden min-h-screen">
    <div className="lg:w-1/2  bg-[#232436] xl:max-w-screen-sm">
        <div className="py-8  bg-[#232436] flex justify-center lg:justify-start lg:px-12">
            <div className="bg-[#232436]  cursor-pointer flex items-center ">
                <div>
                </div>
                <div className=" text-2xl text-white tracking-wide ml-2 font-semibold">
                <img className='max-w-[80vw]' src="./images/logo-white.png"/>
                </div>
            </div>
        </div>      
        <div className="mt-10 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-24 xl:max-w-2xl">
            <h2 className="text-center text-3xl text-indigo-600 font-display font-semibold lg:text-left xl:text-4xl
            xl:text-bold mb-2">Willkommen, Administrator!</h2>
            <p className='text-white'>Melden Sie sich in Ihrem Admin-Panel an</p>
            <div className="mt-12">
                   {err!=='' && <p className='bg-red-100  border-2 border-red-600 shadow-sm rounded text-red py-[8px] px-[10px] mb-[10px]'>{err}</p>   }

                <form onSubmit={loginUser} className='pb-12'>
                    <div>
                        <div className="text-sm font-bold text-gray-300 mb-1 tracking-wide">E-Mail-Addresse</div>
                        <input 
                        className="px-5 rounded w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                        placeholder="admin@mail.com"
                        value={email}
                        onChange={handleEmail}
				        type="email"
                        required/>
                        {error.emailColor==="neutral" ? <small className='w-[300px] inline-block font-semibold neutral text-[grey]'>{error.emailError}</small>:null} 
       {error.emailColor==="red" ? <small className='w-[300px] inline-block font-semibold red text-red-500'>{error.emailError}</small>:null} 
       {error.emailColor==="green" ? <small className='w-[300px] inline-block font-semibold green text-green-500'>{error.emailError}</small>:null}
                    </div>
                    <div className="mt-8">
                        <div className="flex justify-between items-center">
                            <div className="mb-1 text-sm font-bold text-gray-300 tracking-wide">
                            Passwort
                            </div>
                            
                        </div>
                        <input 
                        className="px-5 rounded w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                        placeholder="Enter your password"
                        type="password"
                        value={password}
                        onChange={handlePass}
                        required minLength={8}/>
                        {error.passColor==="neutral" ? <small className='w-[300px] inline-block font-semibold neutral text-[grey]'>{error.passError}</small>:null} 
                        {error.passColor==="red" ? <small className='w-[300px] inline-block font-semibold red text-red-500'>{error.passError}</small>:null} 
                        {error.passColor==="green" ? <small className='w-[300px] inline-block font-semibold green text-green-500'>{error.passError}</small>:null}

                    </div>
                    <div className="mt-10">
                        <button 
                        className="bg-indigo-500 text-gray-100 p-4 w-full rounded-full tracking-wide
                        font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-indigo-600
                        shadow-lg"
                        disabled={error.emailPass===false || error.passPass === false}
                        type="submit" >
                            Einloggen
                        </button>
                    </div>
                </form>
            
            </div>
        </div>
    </div>
    <div className="hidden lg:flex items-center justify-center bg-[#232436] flex-1 h-screen">
        <div className=" transform duration-200 hover:scale-105 ">
            <img src="./images/analytic.png"/>
           
        </div>
    </div>
</div>
  )
}

export default Login