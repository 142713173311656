import React, { useState, useEffect } from 'react';
import Base64PDFViewer from './Base64PDFViIewer';
import HeadBar from '../partials/HeadBar';
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import config from '../config/config';


import toast, { Toaster } from "react-hot-toast";

function OrderInvoices() {
  const [invoiceUrl, setInvoiceUrl] = useState('');
  const [base64Data, setBase64Data] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);


  useEffect(() => {
    
      fetch(`${config.baseUrl}/api/user/invoice?id=${localStorage.getItem('user_invoice_id')}`)
        .then(response => response.json())
        .then((data) => {
          const base64 = data.file;
          setBase64Data(base64)

          const blob = base64toBlob(data.file);
          const url = URL.createObjectURL(blob);
          setInvoiceUrl(url);
        });

  }, []);

  const base64toBlob = (file) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = file.substr('data:application/pdf;base64,'.length);

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
};
 
 
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <Toaster />
            <HeadBar title={"Rechnungen bestellen"} description={""} />
          </div>
          <div className="w-[800px] ml-12 mb-6">
      

            {base64Data && (
              <>
              <Base64PDFViewer base64Data={base64Data} />
              </>
            )}


           
          </div>
        </main>
      </div>
    </div>
  );
}

export default OrderInvoices;
